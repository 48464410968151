import React, { ReactElement } from 'react';
import Header from '../../../../common/layouts/header';
import Footer from '../../../../common/layouts/footer';
import { Container, Grid, MenuItem, MenuList, styled, Typography } from '@mui/material';
import useIsMobile from '../../../../hooks/use-is-mobile.hook';
import { colors } from '../../../../config/theme/colors';
import { Link } from 'react-router-dom';
import { PublicRouteEnum } from '../../routes/enums/route.enum';

const StyledLink = styled(Link)(
  () => `
  margin-top: 4px;
  text-decoration: none;
  color: ${colors.secondary[90]};
  font-size: 14px;
`,
);

const TermsAndConditions = (): ReactElement => {
  const isMobile = useIsMobile();
    const htmlContent = `
    <div class="wrap-text-policy-pages">
      <h1>Website terms and conditions</h1>
      <p>Welcome to Therappy Pty Ltd (ABN 69 666 183 857) and www.Therappy.com.au, our website. We're so glad you're here! By visiting our website and using the services our website provides, you are agreeing to these terms and conditions and any other policies we publish or link to on our website; we like to call this an "Agreement". If you don't accept to our Agreement, we kindly ask that you surf elsewhere. Just know that if you breach our Agreement, we may need to terminate your use of our website. We may also change, suspend or stop providing our website at any time, but we'll always try to let you know if that's the case.</p>
      <p>Now, let's chat about your obligations when using our website.</p>
      <p class='policy-article-upper'>YOUR OBLIGATIONS WHEN USING OUR WEBSITE</p>
      
      <p class='policy-article'>To provide correct information and comply with the law</p>
      <p>Firstly, please provide us with current and accurate information when filling out any of our booking confirmation forms or membership profile. It is also important that you comply with the laws of NSW and Australia when you use our website, including, but not limited to, all intellectual property and cybercrime laws. If you're accessing our website from outside of Australia, please make sure to also comply with all the relevant local laws.</p>
      
      <p class='policy-article'>To only make personal and non-commercial use of our Content</p>
      <p>Next up, please only use the website and its Content for your personal and non-commercial use. We own or license the Copyright in all Content on our website, and any other use is prohibited unless permitted by law, or with our prior written permission (which you can request by emailing help@therappy.com.au). All trademarks on our website belong to their respective owners.</p>
      
      <p class='policy-article'>To use third party software, links etc at your risk</p>
      <p>If you choose to use third-party software, links, or other tools to enhance your experience on our website, please do so at your own risk. We provide the links for your assistance only, and we have no control over those other sites or their content. We do not endorse them in any way, and any use of those sites is at your sole risk. Similarly, we have no control over third-party apps and software and do not make any warranties in relation to them. You will need to read and agree to their terms and conditions before using them.</p>
      
      <p class='policy-article'>To direct concerns about third party products and services to that third-party</p>
      <p>Please note that our website and services may include advertisements for third-party products and services. We have no control over the advertisements or the websites of third parties, and we are not responsible for their content, or their products or services. We do not endorse any of their goods or services, but in some cases, we may have a relationship with that third-party who may pay us a referral fee or a commission when you buy their products and services. If you have any concerns about third-party products or services, please contact that third-party directly. Any purchase you make is a contract between you and them, and we're not involved.</p>
      
      
      <p class='policy-article'>To not rely on any "advice"</p>
      <p>Some of the information we provide on our website may be information related to health and medical, but it's not meant to be health and medical "advice". We provide this information for your general use only. While we try to provide accurate information, it may be historical, incomplete information or based on opinions that aren't widely held. Your personal situation has not been considered when providing the information, so any reliance on this information is at your sole risk. We recommend seeking independent professional advice before relying on the information we provide.</p>
      
      <p class='policy-article-upper'>WE MAKE NO WARRANTIES OR GUARANTEES</p>
      <p>We need you to know that we make no warranties or guarantees about our website or its Content. We can't guarantee our website will always be available, that your use will not be interrupted or that our website is free from viruses or secure; or that our Content is accurate, complete, and current.</p>
      <p>For example, sometimes we will be making changes to our website, or you may come across an old landing page, historical information, incomplete pages, or inaccuracies. If you find any issues, please email us at help@therappy.com.au.</p>
      
      <p class='policy-article-upper'>LIMITATION OF LIABILITY AND INDEMNITY</p>
      <p>We exclude, to the maximum extent permitted by law, any liability in connection with your use of our website and its Content. We are not responsible for any Loss or Damage suffered in connection with your use of our website, its Content, any interruptions, changes, suspension or termination of our website or any events beyond our control.</p>
      
      <p>Additionally, you indemnify us for any Claim arising out of or in connection with any third-party links, websites, apps, software, products, and services; your breach of this Agreement, your breach of the law including your local laws, or your breach of the rights of a third party.</p>
      
      <p class='policy-article-upper'>OTHER</p>
      <p>This Agreement is governed by the laws in NSW, Australia and the parties submit to the jurisdiction of the courts of NSW, Australia. If any part of these terms and conditions is not enforceable, it shall be severed from this Agreement and the remaining provisions will be in full force and effect. The obligations and liabilities in this Agreement survive termination. The failure to exercise our rights or enforce a provision under this Agreement does not waive the future operation of that right or ability to enforce the provision.</p>
      
      <p class='policy-article-upper'>DEFINITIONS</p>
      <p><strong>Claim</strong> means any claim, under statute, tort, contract or negligence, any demand, award, or costs.</p>
      <p><strong>Content</strong> means any content on our website, and could be any trademarks, brand names, logos, all copy, images, art, graphics, music, audio, videos, designs, or any other content.</p>
      <p><strong>Copyright</strong> means all rights pursuant to the <i>Copyright Act 1968</i> (Cth).</p>
      <p><strong>Loss or Damage</strong> means any loss or damage including, but not limited to, any loss of salary, loss of revenue, loss of goodwill, downtime costs, loss of profit, loss or damage to reputation, loss of data, personal injury, property damage or legal costs.</p>
      <p><strong>We, us, or our</strong> means Therappy Pty Ltd (ABN 69 666 183 857) and includes any of our employees, agents, partners, and contractors.</p>
    </div>      
`;
  return (
    <>
      <Header />
      <Container>
        <Grid sx={{ pt: 12, pb: 5 }} container spacing={1}>
          {!isMobile && (
            <Grid item md={3}>
                <MenuList className={'list-item-policy'}>
                <StyledLink to={PublicRouteEnum.PRIVACY_POLICY}>
                  <MenuItem sx={{ mb: 3 }}>Privacy Policy</MenuItem>
                </StyledLink>

                <StyledLink to={PublicRouteEnum.THERAPPY_PRIVACY_POLICY}>
                  <MenuItem sx={{ mb: 3 }}>Therappy Privacy Policy</MenuItem>
                </StyledLink>

                <MenuItem className={'list-item-policy-active'} sx={{ mb: 3, color: colors.primary[70] }}>Website Terms & Conditions</MenuItem>

                <StyledLink to={PublicRouteEnum.SERVICE_PROVIDER_TERMS_CONDITIONS}>
                  <MenuItem sx={{ mb: 3 }}>Service Provider Terms & Conditions</MenuItem>
                </StyledLink>

                <StyledLink to={PublicRouteEnum.CUSTOMER_TERMS_CONDITIONS}>
                  <MenuItem sx={{ mb: 3 }}>Customer Terms & Conditions</MenuItem>
                </StyledLink>
              </MenuList>
            </Grid>
          )}
            {isMobile && (
                <Grid item xs={12}>
                    <MenuList className={'list-item-policy'}>
                        <StyledLink to={PublicRouteEnum.PRIVACY_POLICY}>
                            <MenuItem sx={{ m: 0, p: 0, justifySelf: 'center' }}>Privacy Policy</MenuItem>
                        </StyledLink>

                        <StyledLink to={PublicRouteEnum.THERAPPY_PRIVACY_POLICY}>
                            <MenuItem sx={{ m: 0, p: 0, justifySelf: 'center' }}>Therappy Privacy Policy</MenuItem>
                        </StyledLink>

                        <MenuItem className={'list-item-policy-active'} sx={{ mb: 3, color: colors.primary[70], justifySelf: 'center' }}>Website Terms & Conditions</MenuItem>

                        <StyledLink to={PublicRouteEnum.SERVICE_PROVIDER_TERMS_CONDITIONS}>
                            <MenuItem sx={{ m: 0, p: 0, justifySelf: 'center' }}>Service Provider Terms & Conditions</MenuItem>
                        </StyledLink>

                        <StyledLink to={PublicRouteEnum.CUSTOMER_TERMS_CONDITIONS}>
                            <MenuItem sx={{ m: 0, p: 0, justifySelf: 'center' }}>Customer Terms & Conditions</MenuItem>
                        </StyledLink>
                    </MenuList>
                </Grid>
            )}
          <Grid item xs={12} md={9} lg={8} xl={7}>
            <div dangerouslySetInnerHTML={{__html: htmlContent}}/>
          </Grid>
        </Grid>
      </Container>
      <Footer/>
    </>
  );
};

export default TermsAndConditions;
