import {Box, IconButton, List, ListItem, ListItemButtonProps, ListItemIcon, styled, Theme} from "@mui/material";
import { FC, ReactElement, ReactNode, useContext, useEffect, useState } from "react";
import { colors } from "../../../../config/theme/colors";
import Security from "../../../ui/icon/security";
import useSidebarContext from "../../sidebar/hooks/use-sidebar-context.hook";
import { SidebarContext } from "../../sidebar/context/sidebar.context";
import { ProviderRouteEnum } from "../../../../modules/provider/routes/enums/route.enum";
import Donation from "../../../ui/icon/donation";
import Users from "../../../ui/icon/users";
import Teacher from "../../../ui/icon/teacher";
import UserAdd from "../../../ui/icon/user-add";
import Notebook from "../../../ui/icon/notebook";
import {getImagePath} from "../../../../helpers/image.helper";
import {ImageSizesEnum} from "../../../../enums/image-sizes.enum";
import {shortString} from "../../../../helpers/string.helper";
import {useDispatch, useSelector} from "react-redux";
import {selectAuth} from "../../../../store/selectors";
import useIsMobile from "../../../../hooks/use-is-mobile.hook";
import {logout} from "../../../../store/actions/auth";
import {useConfirmation} from "../../../../hooks";
import Logout from "../../../ui/icon/logout";
import {useNavigate} from "react-router-dom";
import {PublicRouteEnum} from "../../../../modules/public/routes/enums/route.enum";

const ItemText = styled('span')(
  () => `
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
`,
);

const Item = styled('a')(
  ({ theme, color }: ListItemButtonProps & { color: string; theme?: Theme }) => `
    width: 100%;
    color: ${color};
    display: flex;
    align-items: center;
    border-radius: 6px;
    margin: 8px 24px;
    height: 36px;
    justify-content: initial;
    fill: ${color};
    stroke: ${color};
    text-decoration: none;
`,
);

const ActiveItem = styled(Item)(
  ({ background }: ListItemButtonProps & { background?: string }) => `
    color: #006fcc;
    fill: #006fcc;
    stroke: #006fcc;
    background: ${background};
`,
);

const UserInfo = styled(Box)(
    () => `
    display: flex;
    position: absolute;
    bottom: 0;
`,
);

const UserAvatar = styled('img')(
    () => `
    width: 40px;
    height: 40px;
    border-radius: 40px;
    object-fit: cover;
    margin-bottom: 8px;
`,
);

const UserName = styled('p')(
    ({ color }) => `
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    color: ${color};
    margin: 0;
    text-wrap: wrap;
`,
);

const UserEmail = styled('p')(
    ({ theme }) => `
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
    color: ${theme.palette.text.disabled};
    margin-bottom: 8px;
`,
);

const LogoutIcon = styled(Logout)(
    () => `
  stroke: ${colors.secondary[10]};
`,
);

interface SidebarItemProps {
  label: string;
  active: boolean;
  icon: ReactNode;
  href?: string;
  onClick?: () => void;
}

const SidebarItem = ({ label, active, icon, href, onClick }: SidebarItemProps) => {
  const ItemButton = active ? ActiveItem : Item;

  return (
    <ListItem disablePadding>
      <ItemButton
        href={href}
        color={colors.background.BG_1}
        background={colors.secondary[20]}
        onClick={onClick}
      >
        <ListItemIcon sx={{ justifyContent: 'center'}}>
          {icon}
        </ListItemIcon>
        <ItemText>
          {label}
        </ItemText>
      </ItemButton>
    </ListItem>
  );
};

interface Props {
  activeLink: string;
  setActiveLink: (link: string) => void;
}

const SidebarContent = ({ activeLink, setActiveLink }: Props): ReactElement => {
  const { toggle } = useContext(SidebarContext);
  const { authUser } = useSelector(selectAuth);
  const sidebar = useContext(SidebarContext);
  const isMobile = useIsMobile();
  const { Confirmation, showConfirmation } = useConfirmation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const scrollToSection = (to: string) => document.getElementById(to)?.scrollIntoView({ behavior: 'smooth' });

  const redirectToPage = (to: string) => {
      navigate(PublicRouteEnum.LANDING);
      setTimeout(() => scrollToSection(to), 100);
  }

  const handleClick = (section: string) => {
    redirectToPage(section);
    setActiveLink(section);
    toggle();
  };

  const handleLogout = () => {
      showConfirmation({
          text: 'Confirm logout',
          description: 'Are you sure you want to log out?',
          onConfirm: () => {
              dispatch(logout());
              },
      });
  };

  return (
      <>
        {Confirmation}
        <List>
          <SidebarItem
            label="Services"
            active={activeLink === 'services'}
            icon={<Security/>}
            onClick={() => handleClick('services')}
          />
          <SidebarItem
            label="Benefits"
            active={activeLink === 'why_therappy'}
            icon={<Donation/>}
            onClick={() => handleClick('why_therappy')}
          />
          <SidebarItem
            label="How we work"
            active={activeLink === 'how_it_works'}
            icon={<Users/>}
            onClick={() => handleClick('how_it_works')}
          />
          <SidebarItem
            label="Our Therapists"
            active={activeLink === 'our_professionals'}
            icon={<Teacher/>}
            onClick={() => handleClick('our_professionals')}
          />
          <SidebarItem
            label="Therapist Login"
            href={ProviderRouteEnum.SIGN_IN}
            active={false}
            icon={<UserAdd/>}
          />
          <SidebarItem
            label="Contacts"
            active={false}
            icon={<Notebook/>}
            onClick={() => handleClick('')}
          />
        </List>
        {authUser && (
            <UserInfo sx={{ margin: '32px 12px', flexWrap: 'wrap' }}>
                <UserAvatar src={getImagePath(authUser.image_id || 0, ImageSizesEnum.THUMBNAIL)} />
                {sidebar.opened && (
                    <>
                        <Box sx={{ ml: 1, display: 'grid', overflow: 'hidden', maxWidth: isMobile ? '145px' : '145px'}}>
                            <UserName color={colors.primary[110]}>
                                {shortString(authUser.first_name, 18)} {shortString(authUser.last_name, 18)}
                            </UserName>
                            <UserEmail color={colors.secondary[110]}>{authUser.email}</UserEmail>
                        </Box>
                        <IconButton onClick={handleLogout}>
                            <LogoutIcon />
                        </IconButton>
                    </>
                )}
            </UserInfo>
        )}
      </>
  );
};

export default SidebarContent;
