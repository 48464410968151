import React, { ReactElement } from 'react';
import Header from '../../../../common/layouts/header';
import Footer from '../../../../common/layouts/footer';
import { Container, Grid, MenuItem, MenuList, styled, Typography } from "@mui/material";
import useIsMobile from '../../../../hooks/use-is-mobile.hook';
import { colors } from '../../../../config/theme/colors';
import { PublicRouteEnum } from '../../routes/enums/route.enum';
import { Link } from "react-router-dom";

const StyledLink = styled(Link)(
  () => `
  margin-top: 4px;
  text-decoration: none;
  color: ${colors.secondary[90]};
  font-size: 14px;
`,
);

const PrivacyPolicy = (): ReactElement => {
  const isMobile = useIsMobile();
    const htmlContent = `
       <div class="wrap-text-policy-pages">
          <h1>Privacy policy</h1>
          <p>Hi there! We want to let you know how we collect information so that you can feel comfortable sharing it with us. That's why we have this privacy policy which outlines the way we at Therappy Pty Ltd [ABN 69 666 183 857] collect, hold, use and disclose personal information.</p>
          <p class='policy-article-upper'>WHAT PERSONAL INFORMATION WE COLLECT & HOW AND WHY WE COLLECT IT?</p>
          <p class='policy-article'>What personal information do we collect?</p>
          <p>The personal information we collect is generally limited to:</p>
          <ul>
            <li>name and contact details;</li>
            <li> business information, date of birth, driver's licence, job information, name and contact details, opinions and place of birth; and</li>
            <li>any communications we have.</li>
          </ul>
          <p>However, we may also collect:</p>
          <ul>
              <li>sensitive information from you with your consent, such as where we ask for information about your health and membership of professional or trade associations;</li>
              <li>financial information, such as your credit card or debit card details; and</li>
              <li>information about how you use our website, via third parties.</li>
          </ul>
          <p class='policy-article'>How do we collect your personal information?</p>
          <p>The main way we collect information is when you give it to us. You might do this by filling out forms on our website like sign-up or intake forms. Or you might give us information through phone calls, emails, or social media. On occasion we may also collect information from the person that referred you, or other people or organisations.</p>
          
          <p>We love hearing from you, and we promise to keep your information safe and secure.</p>
          <p>We also use cookies on our website. These are little bits of data that help our website work better. They might identify you and keep track of the pages you visit. This helps us improve our website and make it more secure. Plus, it makes it easier for you to use our website! You won't have to fill in your information again and again.</p>
          
          <p>At times personal information may also be gathered from third parties, such as Google Analytics or Facebook Pixel. These third parties may use cookies, web beacons and similar technology to collect or receive information about your habits on the internet.</p>
          <p class='policy-article'>Why do we collect your personal information?</p>
          <p>We need your personal information to:</p>
          <ul>
            <li>communicate with you in relation to your enquiry or booking request;</li>
            <li>verify your identity for security purposes;</li>
            <li>send you news if you have signed up (you can unsubscribe at any time);</li>
            <li>conduct our business, and enable your use of our website, products, and services; and</li>
            <li>in some cases, to comply with our legal obligations, such as record keeping.</li>
          </ul>
          
          <p>We also collect personal information to analyse and enhance our business operations and improve your experience with our business. This is used as statistical information to analyse our website traffic and to customise the content and advertising we provide.</p>
          <p class='policy-article'>You can opt-out of the collection and use of this information by changing your privacy settings or opting out.</p>
          <p>To opt-out of Google advertising you can go here: <a class="link-text-decorate-none" href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</a>.</p>
          <p>To change your Facebook ad preferences, you can go to "Facebook Help Centre", "Manage my account" and "Ad preferences".</p>
          
          <p>Where we collect your financial information, we use it to help you pay for our products and services. Only the staff that need to know this information have access to it, and we only keep it as long as it is necessary. We use SSL certificates to verify your identity and encrypt any data you give us. All financial information is encrypted on our servers, and we do not keep all your data (to prevent unauthorised and duplicated transactions). We do not keep any details of your direct debit, and all information is sent to our bank for processing.</p>
          <p class='policy-article-upper'>WHEN DO WE DISCLOSE PERSONAL INFORMATION AND HOW YOU CAN ACCESS IT?</p>
          <p class='policy-article'>When do we disclose your personal information?</p>
          <p>We use reasonable precautions to make sure your information stays safe and secure. Only authorised personnel can access it, and we keep it in accordance with our legal record keeping obligations. When we don't need it anymore, or it is unsolicited, we destroy it appropriately. We generally will not disclose your personal information unless we have your consent, it's required by law*, or it's necessary for us to provide you with our services.</p>
          <p>* This can include where we are of the reasonable belief that there is a serious risk to life, health or safety of you or another person. For example, if there is evidence of clear danger of harm to self-and/or others, we may be legally required to report this information to the authorities responsible for ensuring safety. This includes if there is a strong suspicion of physical or sexual abuse or emotional, or neglect or exposure to family violence of any person under 18 years of age. A court order could also require us to release information contained in records.</p>
          <p>Sometimes we do disclose your personal information where it is necessary to obtain third-party services, like analytics, data storage, payment providers or advertising services. Some of these third parties may be overseas. We do our best to make sure the providers we use comply with privacy laws and regulations.  <strong>You can opt-out of the collection and use of this information by changing your privacy settings or opting out.</strong></p>
          <p class='policy-article'>How can you access or delete your information?</p>
          <p>If you want to access, correct, or delete your information, please email us at help@therappy.com.au. We're happy to help unless we're required by law to withhold it. And if you have any concerns at all about your privacy, please also email us at <a class="link-text-decorate-none" href="mailto:help@therappy.com.au">help@therappy.com.au</a>.</p>
          
          <p> You can also make a complaint with the Office of the Australian Information Commissioner by phone on 1300 363 992, online at http://www.oaic.gov.au where you can go to the "Lodge a Privacy Complaint with us" page, or post to: Office of the Australian Information Commissioner, GPO Box 5218, Sydney, NSW 2001.</p>
          <p class='policy-article-upper'>ADDITIONAL PROVISIONS FOR EUROPEAN CITIZENS</p>
          <p>If you are a resident of the European Economic Area ("EEA") or the UK you have certain rights and protections under the <i>General Data Protection Regulations</i> ("GDPR") or its UK equivalent, <i>The Data Protection Act</i> 2018. We want you to know that we take these rights seriously and will always do our best to protect your personal information. We're what's called a "controller" under the GDPR as we collect, use, and store your personal information to provide you with our website services and information about them.</p>
          <p>We'll always rely on a lawful reason for processing your information like:</p>
          <ul>
            <li>where you have given us valid express consent to use your personal information we will rely on that consent, and only use the personal or sensitive information for the specific purpose for which you have given consent; and</li>
            <li>where we need comply with the law or act in an emergency, we will rely on that lawful means of processing your personal information.</li>
          </ul>
          <p class='policy-article'>Your Rights</p>
          <p>As an EEA or UK resident, you have various rights including the right to be informed; right of access; to rectification; to object; to restrict processing; to erasure or to be forgotten; to data portability; and the right not to be subject to automated processing.  If you want to access personal information we hold about you, or ask if that the information be corrected, or deleted or otherwise exercise your rights, please contact us at help@therappy.com.au. You can also opt out of email newsletter communications by following the instructions in those emails or by clicking unsubscribe. In some circumstances where we have a legal basis to do so we may continue to process your information after you have withdrawn consent, for example if it is necessary to comply with an independent legal obligation or if it is necessary to do so to protect our legitimate interest in keeping our services secure.</p>
          <p class='policy-article'>Security</p>
          <p>We keep all personal information on our website platform confidential and secure, and it can only be accessed by authorised personnel. We collect what's necessary, and keep it only as long as is necessary, taking into account the purpose for which it was collected. We implement and maintain appropriate technical, security and organisational measures to protect your personal information against unauthorised or unlawful processing and use, and against accidental loss, destruction, damage, theft, or disclosure. We ensure the encryption and pseudonymisation of personal information and we have adequate cyber security measures in place. By providing us with your personal information you consent to us disclosing it to third parties who reside outside the EEA countries and the UK. We will ensure that those third parties are GDPR compliant as much as possible.</p>
          <p class='policy-article'>Thank you!</p>
          <p>Thank you for trusting us with your personal information. Above all, we will do our best to keep it safe and use it responsibly.</p>
       </div>
    `;

  return (
    <>
      <Header />
      <Container>
        <Grid sx={{ pt: 12, pb: 5 }} container spacing={1}>
          {!isMobile ? (
            <Grid item md={3}>
                <MenuList className={'list-item-policy'}>
                <MenuItem className={'list-item-policy-active'} sx={{ mb: 3, color: colors.primary[70] }}>Privacy Policy</MenuItem>

                <StyledLink to={PublicRouteEnum.THERAPPY_PRIVACY_POLICY}>
                  <MenuItem sx={{ mb: 3 }}>Therappy Privacy Policy</MenuItem>
                </StyledLink>

                <StyledLink to={PublicRouteEnum.TERMS_AND_CONDITIONS}>
                  <MenuItem sx={{ mb: 3 }}>Website Terms & Conditions</MenuItem>
                </StyledLink>

                <StyledLink to={PublicRouteEnum.SERVICE_PROVIDER_TERMS_CONDITIONS}>
                  <MenuItem sx={{ mb: 3 }}>Service Provider Terms & Conditions</MenuItem>
                </StyledLink>

                <StyledLink to={PublicRouteEnum.CUSTOMER_TERMS_CONDITIONS}>
                  <MenuItem sx={{ mb: 3 }}>Customer Terms & Conditions</MenuItem>
                </StyledLink>

              </MenuList>
            </Grid>
          ) : (
              <Grid item xs={12}>
                  <MenuList className={'list-item-policy'}>
                  <MenuItem className={'list-item-policy-active'} sx={{ m: 0, color: colors.primary[70], p: 0, justifySelf: 'center' }}>Privacy Policy</MenuItem>
                    <StyledLink to={PublicRouteEnum.THERAPPY_PRIVACY_POLICY}>
                        <MenuItem sx={{ m: 0, p: 0, justifySelf: 'center' }}>Therappy Privacy Policy</MenuItem>
                    </StyledLink>
                    <StyledLink to={PublicRouteEnum.TERMS_AND_CONDITIONS}>
                        <MenuItem sx={{ m: 0, p: 0, justifySelf: 'center' }}>Website Terms & Conditions</MenuItem>
                    </StyledLink>

                    <StyledLink to={PublicRouteEnum.SERVICE_PROVIDER_TERMS_CONDITIONS}>
                        <MenuItem sx={{ m: 0, p: 0, justifySelf: 'center' }}>Service Provider Terms & Conditions</MenuItem>
                    </StyledLink>

                    <StyledLink to={PublicRouteEnum.CUSTOMER_TERMS_CONDITIONS}>
                        <MenuItem sx={{ m: 0, p: 0, justifySelf: 'center' }}>Customer Terms & Conditions</MenuItem>
                    </StyledLink>
                </MenuList>
              </Grid>
            )}
          <Grid item xs={12} md={9} lg={8} xl={7}>
            <div dangerouslySetInnerHTML={{__html: htmlContent}}/>
          </Grid>
        </Grid>
      </Container>
      <Footer/>
    </>
  );
};

export default PrivacyPolicy;
