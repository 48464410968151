import React, { ReactElement } from 'react';
import Header from '../../../../common/layouts/header';
import Footer from '../../../../common/layouts/footer';
import { Container, Grid, MenuItem, MenuList, styled, Typography } from "@mui/material";
import useIsMobile from '../../../../hooks/use-is-mobile.hook';
import { colors } from '../../../../config/theme/colors';
import { PublicRouteEnum } from '../../routes/enums/route.enum';
import { Link } from "react-router-dom";

const StyledLink = styled(Link)(
  () => `
  margin-top: 4px;
  text-decoration: none;
  color: ${colors.secondary[90]};
  font-size: 14px;
`,
);

const ServiceProviderTermsConditions = (): ReactElement => {
  const isMobile = useIsMobile();
  const htmlContent = `
      <div class="wrap-text-policy-pages">
          <h1>Service Provider Terms & Conditions</h1>
          <p>Thank you for choosing Therappy and welcome the terms and conditions governing how we work with you, and our Service Providers.  </p>
          
          <p>We have included the following terms and conditions for use of our Site and Platform. We will do our best to keep things running smoothly for you and our Service Providers. The following terms are your obligations and responsibilities when using or accessing the Therappy platform ("Platform"). These terms and conditions constitute a legally binding agreement between you and Therappy ("Agreement"). You must agree to these terms to access and use Therappy.</p>
          
          <p class='policy-article'>Our Mission</p>
          <p>At Therappy  it is our mission to make the experience of getting and having therapy easier for customers and therapists or Service Providers. The aim of our Platform is to help our 'Customers' make an easy online bookings 'Booking Request' for therapy at a time and place convenient to them.</p>
          
          <p>We 'Therappy' will then do our very best to connect your Booking Request' to one of our highly skilled therapists or healthcare professionals also known as a 'Service Providers' who will travel to meet you our customer at your preferred location 'property' to provide your treatment session.</p>
          
          <p>Please read and understand all our terms, policies, Service Standards, Service Guidelines, and Privacy Policy carefully and ensure you agree with these before using the Therappy Platform.</p>
          
          <p>If you do not agree with any of the terms, policies, or standards then we sadly will have to say goodbye to you, as we cannot accept your booking request on our platform.</p>
          
          <p class='policy-article'>About Us</p>
          <p>Therappy is healthcare technology platform that connects customers to our extensive network of highly experienced background checked professionals also known as 'Service Providers' in just a few clicks.</p>
          
          <p>Therappy.com.au our user-friendly website allows customers to easily browse, customise and book a range of different mobile therapies. Once a booking request has been received, we quicky act to connect customers to the next available mobile Service Provider that travels to customers at a preferred time and location.</p>
          
          <p>You can rest assured, Therappy undertakes a strict vetting process for any Service Provider working on our platform. All Service Providers must sign a Service License Agreement.</p>
          
          <p class="block-ul">We vet our Service Providers for:</p>
          <ul>
              <li>proven qualifications and skill set;</li>
              <li>years of experience in that Service;</li>
              <li>background - e.g. National Police Clearance, Working with Children Clearance, and Professional referees; and</li>
              <li>professional license to practice in their profession.</li>
          </ul>
          
          <p>All of our approved Service Providers have access to our Therappy App, which allows them to accept, change or decline your booking requests in just a few taps. If you love the service and want to rebook with them, they can manage all your bookings on the App ensuring you get the care you need when you want it.</p>
          
          <p class='policy-article-upper'>FACILITATOR ONLY</p>
          <p>Therappy is not party to any agreement made between you and the Customer.</p>
          <p>The App and Platform provide a booking and third-party payment service by which users can make a booking request for a service from Service Providers. We are a facilitator only. You should exercise your common sense and caution to protect your and others' personal property and personal safety. You agree that it is your responsibility to take reasonable precautions in all actions and interactions with any third party you interact with in or through the Service.</p>
          <p>You are wholly responsible for ensuring that your Services comply with applicable regulations, laws, and third-party agreements.</p>
          
          <p class='policy-article-upper'>NON SOLICITATION</p>
          <p>You acknowledge and agree not to engage any Customer met via our Platform for paid services outside of this Agreement while a Service Provider of Therappy and for up to 12 months after termination of this Agreement.</p>
          <p class='policy-article'>Agreement to provide Professional Services</p>
          <p>This is a licence agreement between Therappy as the licensor and you (you, your) as the licensee. We grant you a revocable, non-exclusive licence for the Term to use and access the Platform and App for the permitted use of accepting Booking Requests and providing Services to Customers, on the terms and conditions of this Agreement.</p>
          
          <p class="block-ul">You acknowledge and agree that:</p>
          <ul>
              <li>Therappy does not employ you or any other Service Providers;</li>
              <li>Therappy does not provide, perform, or conduct or offer to or provide, perform, or conduct any Services nor does it act as an agent or broker;</li>
              <li>Therappy does not participate, and is not responsible for participating, in any interaction between you and Customers except to the extent necessary to provide the Platform and the Support to facilitate such interaction, including the invoicing and processing of the Service Fee from your behalf and to deal with any complaints.</li>
          </ul>
          <p>By using the Platform, you agree to observe and comply with all laws and regulations relating to the Services and your engagement by the Customers.</p>
          
          <p>When a Customer confirms a Booking Request, Therappy will send out a notification of the Booking Request to all available Service Providers (subject to the terms and conditions of this Agreement and the Customer's terms and conditions).</p>
          <p class="block-ul">As a condition of Therappy making Booking Requests available to you, you must, at all times during the Term:</p>
          <ul>
              <li>have access to the Professional Equipment in order to provide the Services; </li>
              <li>have completed the Induction and onboarding;</li>
              <li>be registered as a Service Provider on the Therappy Platform; and</li>
              <li>accept and comply with the terms and conditions set out in this Agreement.</li>
          </ul>
          
          <p>Once a Booking Request has been accepted by a Service Provider, you must perform the Booking by providing the Services directly for the Customer in accordance with this Agreement; or if you can no longer attend a Booking; cancel the Booking as soon as possible via the Therapist App. This will allow the Booking Request to be accepted by another Service Provider on the Therappy Platform.</p>
          <p>You must not sub-licence any of its rights under this Agreement without the prior written consent of Therappy.</p>
          
          <p class='policy-article'>Pre-requisites to registration</p>
          <p>When signing up to use or access the Platform to provide Services, Therappy will request Service Providers to upload recent (no less than 3 years) Police Clearance, Working with children check, Professional qualifications, and 2 references and your ABN. Where these are not provided, we will not be able to register you. Where requested you must immediately provide us with updated documentation. Where this is not provided or updated, we may terminate your account and registration.</p>
          
          <p>Therappy will notify Service Providers of their successful admission to the onboarding process via an email Service Providers must then complete the onboarding process and induction training.</p>
          
          <p>On completion of the onboarding process and induction training, you will be notified by both email and inbox of the Therapist App and that you are 'active' on the platform.</p>
          
          <p class='policy-article'>Appointment requests or bookings</p>
          <p class="block-ul">You acknowledge and agree that:</p>
          <ul>
              <li>you are solely responsible for ensuring you update your availability on the Therapist App and ensuring notifications are allowed to accept Booking Requests;</li>
              <li>you must not contact any Customer directly to conduct services privately outside of the Therappy Platform whilst in an Agreement with Therappy;</li>
              <li>it is at your discretion to accept or reject a Booking Request;</li>
              <li>each Booking Request will be sent out to all available Service Providers, who have made themselves available to accept Booking Requests in the Therapist App and Therappy Platform within the specified location, who meet the professional skill requirements and service requested. The first Service Provider who meets these requirements to accept the booking gets the appointment;</li>
              <li>direct Re-booking Requests by a Customer to a Specific Service Provider will be sent via the Platform to that Service Provider in order for them to accept or decline;</li>
              <li>there is limited time to accept or decline a Rebooking Request. Should you decline the booking, then the Rebooking Request is sent out to all other available Service Providers for that particular date, time, locality, and specific service;</li>
              <li>where you accept the Booking, you must complete the service request;</li>
              <li>you are solely responsible for any service-related issues that arise between the you and the Customer; we do not have any responsibility for or control over the Customer;</li>
              <li>you are solely responsible for the quality and safety of the Services and Therappy is not responsible for the agreements or any other legal aspects relevant to the Services that are facilitated through the Platform, other than as may be required by law;</li>
              <li>herappy does not guarantee Booking Requests or Rebooking Requests for a particular Service Provider through the Therappy Platform; and</li>
              <li>you are solely responsible for ensuring your own availability to accept Bookings has been actioned in the Therapist App.</li>
           </ul>
          
          <p>You further acknowledge an agree that Therappy is solely entitled without any reason, to stop or block booking requests to Service Providers at anytime.</p>
          
          <p class='policy-article'>Service Fee</p>
          <p>You acknowledge and agree that you are only entitled to receive the Service Fee once the Services are completed in accordance with this Agreement.</p>
          
          <p>Services are deemed to be completed under this Agreement only when you have completed the Services in accordance with the Booking and clicked on the "complete" button function on the Therapist App.</p>
          
          <p class='policy-article'>Customer cancels a Booking</p>
          <p class="block-ul">In the event a Customer cancels a Booking:</p>
          <ul>
              <li>within four (4) hours before the scheduled Booking time, you are entitled to the full Service Fee, minus the Platform Fee.;</li>
              <li>within twenty-four (24) hours before the scheduled Booking time but with at least four (4) hours' notice before the scheduled Booking time, you are only entitled to receive fifty (50) percent of the Service Fee, minus the Platform Fee;</li>
              <li>within one (1) hour after you have accepted the Booking Request, the Customer is entitled to a refund of the Service Fee less the Platform Fee payable by the Client to Therappy.</li>
          </ul>
          <p class='policy-article'>Customer is running late or access to the Property is prohibited</p>
          <p>In the event the Customer is running late, or access is denied, you are entitled to the full Service Fee, minus the Platform Fee. For the purposes of this clause, running late, or no ability to access is considered more than 20 minutes after the scheduled booking start time.</p>
          
          <p class='policy-article'>Payment to Service Providers</p>
          <p>Therappy will pay you the Service Fee less the Platform Fee and less any other amount which Therappy is entitled to deduct under this Agreement. Therappy will pay you by direct deposit to your nominated bank or Stripe account on the Processing Date.</p>
          
          <p>Completed Bookings will be available for you to view in the Therapist App.</p>
          
          <p>If you have not completed any Bookings during any particular Processing Period, you will not be entitled to receive any Service Fees for that particular week. The total Service Fee payable to you on each Processing Date is calculated by reference to total value of tax invoices issued on behalf you through the Platform which have been paid by the relevant Customers, in respect of Bookings completed by you during the relevant Processing Period.</p>
          
          <p>You acknowledge and agree that the Platform will generate tax invoices on your behalf which will include your name, including any trading name, ABN, and other details that required by law or are otherwise reasonably necessary to include, on the invoices issued to Customers. </p>
          
          <p>You must at all times ensure that you upload onto the Platform or Therapist App the up-to-date details required by law to be included on any tax invoice issued to Customers.</p>
          
          <p>You acknowledge and agree that Therappy may amend the process for the collection of Service Fees and payment of Service Fees from time to time at its sole discretion.</p>
          
          <p class='policy-article'>GST, VAT and Tax</p>
          <p class="block-ul">You acknowledge and agree:</p>
          <ul>
              <li>the Service Fees are inclusive of any applicable GST or VAT and that you are solely responsible for the remission of any GST or VAT payable to their respective government tax collection agencies (for example, ATO in Australia and IRS in United States);</li>
              <li>you will be responsible for the payment of all Taxes arising out of the provision of the Services or assessed on any Service Fees payable or paid under this Agreement;</li>
              <li>you will be responsible for making any superannuation guarantee contributions to any employees of you that is required by law;</li>
              <li>you indemnify Therappy from and against any and all liabilities for any tax or superannuation liability arising in respect of any payments it receives on behalf of, or makes to you; and</li>
              <li>Therappy may, in its discretion, charge Customers a Platform Fee or any other fee for the use of the Platform, and Therappy will be entitled to any such fee absolutely.</li>
           </ul>
          
          <p class='policy-article'>Fees for Services not delivered, cancelled or abandoned</p>
          <p class="block-ul">If you Abandon the Booking:</p>
          <ul>
              <li>with more than twenty-four (24) hours' notice before the scheduled Booking Time, you must pay, on the Processing Date, an amount equivalent to twenty-five (25) percent of the Platform Fee otherwise payable to Therappy had you not abandoned the Booking;</li>
              <li>within twenty-four (24) hours before the scheduled Booking time but with at least four (4) hours' notice before the scheduled Booking time, you must pay, on the Processing Date, an amount equivalent to fifty (50) percent of the Platform Fee otherwise payable to Therappy had you not abandoned the Booking;</li>
              <li>within four (4) hours before the scheduled Booking Time, you must pay, on the Processing Date, an amount that is equivalent to the full Platform Fee otherwise payable to Therappy had you not abandoned the Booking.</li>
          </ul>
          <p>Prior to, or on the Processing Date, Therappy will issue you with a tax invoice for the total Platform Fee payable by you to Therappy for that relevant Processing Period. You agree and irrevocably direct that on each Processing Date, Therappy is to deduct the total Platform Fee payable by you to Therappy from the Service Fee payable to you for that relevant Processing Period.</p>
          
          <p class='policy-article'>Incomplete Bookings</p>
          <p>If you fail to attend the agreed location and or Property at the time of the  confirmed Booking, or do not complete the Service in full for the agreed amount of time, or fail to select 'complete' in the pop up which is generated by the Platform in the Therapist App, you will not be deemed to have successfully completed the Services, and Therappy will act in accordance with the directions of the Customer in determining whether you are entitled to be paid all or part of the Service Fee for the relevant Booking.</p>
          
          <p>Any decision made in this regard is final and not subject to review or negotiation.</p>
          
          <p>Further information may be requested from you in relation to the Booking (which may be based on information obtained by Therappy from data recorded via the Platform in relation to any Booking) before any determination is made in relation to the payment of the Service Fee. Any decision will be provided to you via the Platform.</p>
          
          <p class='policy-article'>Expenses</p>
          <p>You are solely responsible for the payment of all costs and expenses incurred in performing the Services including without limitation, the costs associated with the Professional Equipment and any travel expenses incurred by you, and any and all other expenses relating to the use of the Platform. You acknowledge that it is solely your responsibility to accept and complete Bookings, and Therappy does not guarantee, represent, or warrant that you will have any bookings on the Platform.</p>
          
          <p class='policy-article'>Ownership and Intellectual Property Rights</p>
          <p>Therappy retains ownership of the App and the Platform at all times, including all Intellectual Property (IP) Rights of the App and the Platform. All existing goodwill in, or associated with the Application and the Platform, is owned by, and vested in, and all future goodwill arising out of the use by you of the App and the Platform will vest in and remain the exclusive property of Therappy.</p>
          
          <p class='policy-article'>Damage and Loss to Property</p>
          <p class="block-ul">You acknowledge and agree that:</p>
          <ul>
              <li>you will be liable for any loss or damage to the Property caused by you while you are providing the Services; and</li>
              <li>you will be personally liable for any loss or damage (including bodily harm) suffered by the Customer as a result of you providing the Services.</li>
         </ul>
          <p>Notwithstanding any other clauses in this Agreement, you acknowledge and agree that Therappy does not have any responsibility to reimburse you or the Customer for any damage to the Property or loss or damage (including bodily harm) to a Customer whatsoever, and you indemnifies, releases and holds Therappy harmless from any cost, expenses or liabilities incurred directly or indirectly in connection with any Services.</p>
          <p>In the event that Therappy is notified of any loss or damage in respect of the Property or the Customer, Therappy will take reasonable steps, as directed by the Customer, to ascertain whether all or part of the loss or damage has been caused by you and you shall fully cooperate with Therappy in relation to any information required by Therappy.</p>
          
          <p>You acknowledge and agree that, without limiting any other term of this Agreement, if Therappy determines all or part of the loss or damage was caused by you (including, but not limited to, a negligent act or omission or breach of this Agreement), Therappy may in its absolute discretion deduct from any Service Fee held by Therappy on your behalf an amount which represents the cost of repairing and replacing that part of the Property as lost or damaged by you and any loss suffered by the Customer as a result of the loss or damage caused by you.  </p>
          
          <p>To the extent that the total Service Fees held by Therappy on your behalf at the relevant time are not sufficient to cover the amount being deducted as above, you must immediately pay or reimburse Therappy for the shortfall amount.</p>
          
          <p class='policy-article'>Insurance</p>
          <p>You must obtain and maintain any necessary and adequate insurance(s) throughout the Term, (including professional indemnity and public liability insurance) on terms that are satisfactory to Therappy in Therappy' absolute discretion, and otherwise comply with any other legislation as applicable.</p>
          
          <p>You will be obliged to produce to Therappy at Therappy' request, or to the Customer at Customer's request, original documentation to confirm the validity of any and all necessary insurance policies required to be maintained by you.</p>
          
          <p>You acknowledge that you are not entitled to the benefit of any insurance policies (including for accidents, third party liability, workers' compensation, or other indemnity-based insurance) that Therappy may hold in relation to its business.</p>
          
          <p class='policy-article'>Professional Equipment</p>
          <p>You acknowledge and agree that you are expected to own, lease or otherwise have authorised access to the Professional Equipment. You are solely responsible for any maintenance, up-keep and insurance of the Professional Equipment which you choose to use to complete the Services and acknowledge that you will not be entitled to be reimbursed by Therappy for any expenses or costs relating to the Professional Equipment, including in relation to any insurance which you may choose to obtain in respect of any of the Professional Equipment or the costs associated with normal wear and tear.</p>
          
          <p class='policy-article'>Professional Business assigning booking requests to employees</p>
          <p class="block-ul">You are permitted to assign a Booking Request to an employee of your registered business, under your ABN (Australian Business Number) by you on the basis that they are registered and approved by Therappy and have met all requirements to undertake work via the platform, and that you acknowledge and agree that you are responsible for:</p>
          <ul>
              <li>ensuring that the Assigned Service Provider is made aware of this Agreement and the terms contained herein and is bound by this Agreement to the same extent as you;</li>
              <li>ensuring that the Assigned Service Provider complies with the terms of this Agreement; and</li>
              <li>payment to the Assigned Service Provider in respect of the provision of the Services performed by the Assigned Service Provider.</li>
          </ul>
          <p>Further to clause above, in no circumstances will Therappy be liable to make payment of any Service Fee (or part thereof) to an Assigned Service Provider, including on your behalf.</p>
          <p>You acknowledge and agree you are liable for the acts or omissions of your employees as well as any Assigned Service Provider (and any employees of the Assigned Service Provider) in respect of the provision of the Services. A breach of any of the terms of this Agreement by an Assigned Service Provider will be deemed to be a breach by you, and Therappy shall be entitled to exercise its rights (as relevant) against you in relation to any such breach.</p>
          <p>You further acknowledge and agree that the performance of Services by any employee of yours is not considered to be an assignment of this Agreement.</p>
          
          <p class='policy-article'>Service Provider Contractual Relationship</p>
          <p class="block-ul"> You represent and warrant to Therappy that you:</p>
          <ul>
              <li>have legal capacity, full right, power, and authority to enter into this Agreement;</li>
              <li>are not subject to any legal restrictions preventing you from performing the Services;</li>
              <li>have completed the onboarding, induction training and have proof of your professional qualification to perform the Services;</li>
              <li>will in performing the Services, provide services within scope, act with a duty of care and provide evidence based clinical service;</li>
              <li>will be courteous and polite to Customers when providing Services,</li>
              <li>will not be under the influence of drugs or alcohol in performing the Services;</li>
              <li>will attend to the location or Property of the confirmed Booking within the timeframe specified in the Booking Request;</li>
              <li>will complete all accepted Booking Requests in accordance with this Agreement;</li>
              <li>will not disparage, comment unfairly about or defame Therappy, its directors or employees, any other Service Providers on the Therappy platform, any Customers, and/or the business either during or after the Term, including on any form of social media, and whether or not by identifying any particular person;</li>
              <li>will not, in providing the Services, participate or act in a manner contrary to the interests of Therappy or Customers or otherwise commit any act which may bring Therappy into disrepute or which directly or indirectly damages Therappy's reputation;</li>
              <li>will act lawfully and comply with any applicable policies, licences, laws, regulations, industry codes of conduct, health and safety requirements in Australia while performing the Services;</li>
              <li>have complied with all applicable legislation;</li>
              <li>if applicable, have advised Therappy of your ABN; APHRA registration or other professional registration;</li>
              <li>if applicable, are registered for GST purposes.</li>
          </ul>
          
          <p class='policy-article'>Confidentiality</p>
          <p>You acknowledge and agree at all times before, during and after a Booking Request to keep personal information and data confidential and will not disclose, discuss, copy or transmit without prior approval of the Customer or Therappy, except where obligated to undertake your commitment as a health professional when documenting clinical notes into a cloud based system or similar such as Cliniko or similar for legal documentation purposes.</p>
          
          <p>The obligations of confidentiality will survive termination or expiry of this Agreement.</p>
          
          <p class='policy-article'>Privacy</p>
          <p>In performing the Services, you and Therappy agree to comply with the Australian Privacy Principles as set out in the <i>Privacy Act 1988</i> (Cth) and any other applicable legislation or privacy guidelines.</p>
        <p>Any personal or sensitive information (such as police records checks) held by Therappy in relation to you must be maintained in accordance with Therappy's Privacy Policy which is available at <a class="link-text-decorate-none" href="https://Therappy.com.au/privacy/">https://Therappy.com.au/privacy/</a> and may only be disclosed if required by law.</p>
        <p>You authorise Therappy to submit a National Police History Check and Working with Children Check in relation to you, and Therappy may request these checks at any time in relation to you or any of its employees or subcontractors.  You acknowledge and agree that this is a condition of you using our Site and Platform.</p>
        <p class="block-ul">You agree to respect the privacy of Customers and must not breach of the privacy, by means of,</p>
        <ul>
            <li>sharing private or personal information without an individual's consent;</li>
            <li>unwanted or unsolicited contact with Customers; or</li>
            <li>unwanted or unsolicited visits to Customers.</li>
        </ul>
        
        <p class='policy-article'>Health and Safety </p>
        <p class="block-ul">You and/or the Assigned Service Provider acknowledge and agree that you and/or the Assigned Service Provider:</p>
        <ul>
            <li>are responsible for being in good health physically and mentally before taking and accepting a Booking Request;</li>
            <li>are solely responsible for your own safety while performing while attending to or undertaking the agreed Services;</li>
            <li>must immediately cease performing the Service where it is evident that there is any risk to yourself or the Customer;</li>
            <li>ensure you follow safe and appropriate work health and safety practices such as manual handling techniques when lifting, rolling, or manoeuvring a Customer;</li>
            <li>ensure to check for any fall's risks to yourself and your Customer when navigating Professional Equipment or the Customer onto a 'plith' to perform a treatment;</li>
            <li>ensure adequate and protective hygiene practices such as appropriate hand washing and wiping down of equipment where and when possible;</li>
            <li>ensure all Professional Equipment is in good and safe working conditions;</li>
            <li>ensure to check any special instructions, such as stairs, pets, or parking in the Customers Booking Request.</li>
        </ul>    
        <p>You and /or the Assigned Service Provider further acknowledge and agree that Therappy  has no authority to supervise the performance or quality of the Services you deliver. You or the Assigned Service Provider agree that Therappy is not under any obligation to provide any Service-related training to you in respect of the Services conducted.</p>
        
        <p>Therappy holds the right to suspend or 'block' any Service Provider or Customer from using the Platform where an incident against either you or Customer has been reported to Therappy that may negatively impact or cause harm to the health and safety of either, both or other general public.</p>
        
        <p>You or the Assigned Service Provider must ensure that each Professional Equipment used is in good condition, safe to use and is clinically relevant or appropriate. You or the nominated Service Provider will immediately notify Therappy of any health and safety incidents that occur before, during or after a booking.</p>
        
        <p class='policy-article'>Modification, Suspension and Termination</p>
        <p class="block-ul">You acknowledge that Therappy may, at any time with or without notice and without any payment or refund:</p>
        <ul>    
            <li>modify or discontinue the Platform; and/or</li>
            <li>modify, suspend, or terminate your access to, or use of the Platform.</li>
        </ul>
        <p class="block-ul">You acknowledge and agree that Therappy will not be liable to you or any other party for any:</p>
        <ul>
            <li>suspension or termination of your access to the Platform;</li>
            <li> loss or damage, including loss of profit as a result of suspension or termination of your access to the Platform;</li>
            <li>deletion of any information or materials contained on the Platform uploaded or posted by yourself, or any information you have provided to a Customer through the Therapist App or Platform.</li>
        </ul>   
        <p class="block-ul">Following a 'Termination Event' Therappy will with immediate effect terminate this Agreement by notice in writing to you:  Termination of Events in this Agreement include, but are not limited to where you or any Assigned Service Provider:</p>
        <ul>
            <li>have breached any terms of this Agreement during the Term;</li>
            <li>have participated in unlawful and/or fraudulent behaviour;</li>
            <li>have instigated unsafe or anti-social online or in person behaviours which impact other Service Providers, Customers or any other individuals or businesses associated with Therappy; or</li>
            <li> have threatened or resolved to become or is in jeopardy of becoming subject to any form of insolvency administration.</li>
        </ul>    
        <p>Upon termination you must immediately stop accepting and completing any Services or Bookings and inform Therappy of any upcoming Booking which cannot be completed. You must contact us at  <a class="link-text-decorate-none" href="mailto:help@therappy.com.au">help@therappy.com.au</a> to arrange repayment for any money outstanding to Therappy.</p>
        
        <p>If for any reason you cannot pay or repay any sums owed to us, you agree that Therappy is entitled to deduct any sums owing from amounts payable to yourself, including any earnings from previous 'completed' bookings prior to termination. Therappy will pay you for any services 'completed' which have been received and not yet paid prior to the date of termination.</p>
        
        <p class='policy-article'>Non-solicitation</p>
        <p>You acknowledge and agree not to engage any Therappy Customers gained from the Therappy Platform for your own or other business' profitable benefit. You acknowledge and agree that failure to comply with this clause will negatively impact the value of Therappy as a business.</p>
        
        <p>You further acknowledge and agree that any breach of this clause will result in Therappy seeking an injunction from a court of competent jurisdiction, this may be the same if Therappy believes there is breach in your compliance with this clause.</p>
        
        <p>For the avoidance of doubt, there is no restriction to any other form of employment outside of this Agreement with Therappy or the use of any other Platform.</p>
        
        <p class='policy-article'>Liability and Indemnity</p>
        <p>You acknowledge and agree that you are responsible for any losses, costs, expenses to a third party or Therappy for any negligence or breach incurred during the Term.</p>
        
        <p>You will immediately advise  Therappy of any or all Losses suffered or incurred by Therappy in connection with this Agreement.</p>
        
        <p class="block-ul">You indemnify Therappy from and against:</p>
        <ul>
            <li>the negligence of any party or third party;  </li>
            <li>the manner in which the Services are performed by you, including but not limited to any act or omission by you in connection with the performance of the Services;</li>
            <li>the decision of any court, tribunal, or other authority in respect of the classification of you as either an employee or contractor under common law or statute;</li>
            <li>deemed employees of you including in relation to employee entitlements; and any taxes, fees, charges, and any other imposts.</li>
        </ul>   
        <p>This indemnity is a continuing obligation which is separate and independent from any other obligations of the parties under this Agreement, and it survives termination or expiry of this Agreement.</p>
        
        <p>You acknowledge and agree that it is not necessary for Therappy to incur any expense or cost or make any payment before enforcing the indemnity in clause and that you must pay any money due to Therappy under this indemnity on demand by Therappy.</p>
        
        <p>You acknowledge and agree that Therappy as both a Platform and business will not be liable for any loss, damage or loss of profit related to business or Platform issues, errors, malfunctions or bugs on the Platform or Therapist App.</p>
        
        <p>You acknowledge and agree Therappy to the maximum extent permitted by law will not be liable for any loss, damage either indirect, special, or consequential which be either suffered or incurred by you as a Service Provider using the Platform.</p>
        
        <p>To the maximum extent permitted by law, any condition or warranty by Therappy which would otherwise be implied into this Agreement is hereby excluded.</p>
        
        <p>The obligations under this clause will survive termination or expiry of this Agreement.</p>
        
        <p>For the avoidance of doubt, nothing in this Agreement limits or affects any agreement between you and the Customer as to liability for loss and damage to the Property or loss to the Customer arising from the Services provided by you.</p>
        
        <p class='policy-article'>Dispute Resolution</p>
        <p class="block-ul">In the case of a dispute arising out of or in connection with this Agreement, the parties agree to the following dispute resolution procedure:</p>
        <ul>
            <li>the Complainant must provide a Dispute Notice to the Respondent;</li>
            <li>upon receipt of a Dispute Notice by the Respondent, the Parties will arrange to meet in good faith to seek to resolve the dispute amicably by agreement between them;</li>
            <li>if the parties cannot resolve the dispute within two (2) weeks of the Dispute Notice having been received by the Respondent, either party may refer the matter to a mediator. </li>
            <li>if the parties cannot agree on who the mediator should be, the Complainant will ask the Law Society of New South Wales to appoint a mediator.</li>
            <li>the mediator will decide the time and place for mediation. </li>
            <li>the Parties must attend the mediation recommended by the mediator, in good faith, to seek to resolve the dispute through mediation.</li>
        </ul>    
        <p>Each party will bear their own legal costs in any dispute. The cost of a mediator appointed pursuant to this clause will be shared equally between each party. Any attempts made by the parties to resolve a dispute pursuant to this clause will be without prejudice to any other rights or entitlements of the parties under this Agreement, by law or in equity.  For the avoidance of doubt, nothing in this clause prejudices the right of either party to seek urgent injunctive, interlocutory, or declaratory relief from a court in connection with the dispute without first having to attempt to resolve the Dispute in accordance with this clause.</p>
        <p class='policy-article'>General</p>
        <p><strong>Notices: </strong>We will give notice and provide communications to you by text, email, by posting on the Site in the Member Hub, or other reasonable electronic means.  You must give notice to us in writing via email to <a class="link-text-decorate-none" href="mailto:help@Therappy.com.au">help@Therappy.com.au</a> or as otherwise expressly provided. Please report any violations of this Agreement to <a class="link-text-decorate-none" href="mailto:help@Therappy.com.au">help@Therappy.com.au</a>.</p>
        <p><strong>Electronic communications: </strong>You consent to receiving communications from us in an electronic form, and agree that all terms and conditions, Agreements, notices, documents, disclosures, and other communications ("<strong>Communications</strong>") that we provide to you electronically satisfy any legal requirement that such Communications would satisfy if it were in writing.  Your consent to receive Communications and do business electronically, and our Agreement to do so, applies to all of your interactions and transactions with us.</p>
        <p><strong>No third-party beneficiaries: </strong>You understand and agree that, except as otherwise expressly provided in this Agreement, there shall be no third-party beneficiaries to this Agreement.</p>
        <p><strong>Governing law and jurisdiction: </strong>This Agreement and the relationship between you and Therappy, will be governed by the laws of the State of New South Wales, Australia.  The parties agree to the non-exclusive jurisdiction of the courts of New South Wales, Australia, and of courts entitled to hear appeals from those Courts.</p>
        <p><strong>Assignment: </strong>You must not transfer, assign, or delegate Your right and/or duties under this Agreement to anyone else and any attempted assignment or delegation is void, except as expressly permitted in relation to Assigned Service Providers.  We may assign or novate this Agreement at any time by giving notice to you.</p>
        <p><strong>Headings: </strong>Headings and paragraph titles are for convenience only and do not form part of the terms of this Agreement.</p>
        <p><strong>Delay and waiver: </strong>Any delay or failure by Therappy to exercise or enforce any right or provision of this Agreement will not constitute a waiver of such right or provision.  No waiver by Us will have effect unless such waiver is set forth in writing, signed by Us, nor will any such waiver of any breach or default constitute a waiver of any subsequent or similar breach or default.</p>
        <p><strong>Whole agreement: </strong>This Agreement constitutes the entire Agreement between You and Therappy with respect to its subject matter, and supersedes all prior oral or written understandings, communications, or agreements.</p>
        <p> <strong>Severability: </strong>If for any reason a court holds any provision of this Agreement, or portion thereof, to be unenforceable, that provision of the Agreement will be read down to the minimum extent necessary so as to give full effect to the intent of the parties, and the remainder of this Agreement will continue in full force and effect.</p>
        <p> <strong>Survival: </strong>All provisions that by their nature survive expiration or termination of this Agreement shall so survive, including all limitations on liability explicitly set forth herein and our proprietary rights in and to the Site, Content provided by Us, Our Technology, and the Services.</p>
        <p> <strong>Amendments:  </strong>We may amend the terms of this Agreement from time to time by publishing the amended terms to our website or by giving electronic notice to you. It is Your sole responsibility to check the Site from time to time to view any amendments to this Agreement.  The amendment takes effect upon your express acknowledgement or when you continuing to access and/or use the Site and/or Services.</p>
        <p><strong>Severability</strong> Any provision of, or the application of any provision of this Agreement which is prohibited in any jurisdiction is, in that jurisdiction, ineffective only to the extent of that prohibition. Any provision of, or the application of any provision of this Agreement, which is void, illegal or unenforceable in any jurisdiction does not affect the validity, legality, or enforceability of that provision in any other jurisdiction or of the remaining provisions in that or any other jurisdiction. If a clause is void, illegal or unenforceable, it may be severed without affecting the enforceability of the other provisions in this Agreement</p>
        
        <p class='policy-article'>Definitions</p>
        <p><strong>Abandon</strong> the Booking means a decision made by a Service Provider to cancel a Booking the Service Provider can no longer attend by clicking the "Abandon Booking" function on the Platform.</p>
        <p><strong>Booking</strong> means an accepted Booking Request (which can be extended by a Customer after the Service Provider has accepted the Booking Request) for the provision of the Service which has been processed on the Platform and communicated to you via the Platform.</p>
        <p><strong>Booking Request </strong>means a booking request made by a Customer through the Platform.</p>
        <p><strong>Booking Request details</strong> means the details a Customer has entered into the Booking Request including but not limited to the details of the Property, the requested time and date of the Service, the type of Service required, the preferred gender of the Service Provider, preferred Booking Time, and Customer contact details.</p>
        <p><strong>Content</strong> means all material, content and information made available on the Platform and/or Therappy's official social media accounts, including but not limited to feedback, reviews and ratings provided by you, users of the Platform or Website and Service Providers, any written text, graphics, images, photographs, logos, trademarks, audio material, video material and any other forms of expression.</p>
        <p><strong>Customer</strong> means a person who uses the Platform to book in a service with a Service Provider.</p>
        <p><strong>Direct Booking Request</strong> means a Booking Request made by a Customer offered to a specific Service Provider.</p>
        <p><strong>Dispute Notice </strong>means a written notice given to the Respondent that explains the nature of the Dispute, what outcome the Complainant wishes to achieve and what action the Complainant believes will settle the Dispute.</p>
        <p><a class="link-text-decorate-none" href="https://blys.freshdesk.com/a/solutions/articles/33000265175"><strong>General Health Services Code of Conduct</strong></a> means a Code of Conduct relating to the provision of health services similar or substantially similar to the Code of Conduct in Schedule 3 of the Public Health Regulation 2012 (NSW) in the state where you provide the Services.</p>
        <p><strong>GST</strong> means Goods and Services Tax pursuant to A New Tax System (Goods & Services Tax) Act 1999 (Cth) and the related imposition Act of the Commonwealth.</p>
        <p><strong>Intellectual Property Rights </strong>means all present and future rights anywhere in the world conferred by statute, common law, or equity in or in relation to any copyright, trademarks, designs, patents, circuit layouts, business and domain names, inventions, confidential information, trade secrets and other results of intellectual activity in the industrial, commercial, scientific, literary, or artistic fields.</p>
        <p><strong>Losses</strong> mean any and all actions, claims, proceedings, judgments, demands, liabilities, losses, costs (including reasonable legal costs), expenses, penalties, fines and damages which may be brought or awarded against Therappy or suffered, incurred or paid by Therappy (howsoever arising) in respect of, arising out of or in connection with the  Services provided by that Service Provider (or any of the Service Providers' employees or agents), a violation of any law by the Service Provider, or a breach of the terms, conditions, covenants, warranties and representations (express or implied) contained in these Terms on the part of the Service Provider (or any of the Service Provider's employees or agents) and include loss of data, interruption of business or any consequential or incidental damages.</p>
        <p><strong>Platform Fee</strong> means the amount inclusive of GST displayed on the Platform as being payable by the Customer to Therappy for the use of the Platform and Support on the acceptance of a Booking Request.</p>
        <p><strong>Platform</strong> means the suite of software applications including the Support that enables a Customer to book a Service Provider offered via the Mobile Application or the Website.</p>
        <p><strong>Processing Date</strong> means a specific date, for example Tuesday of each calendar week, when the Service Fee for the Processing Period is transferred to your nominated bank or Stripe account. </p>
        <p><strong>Processing Period</strong> means a period of time, for example a week or a month, that is used to calculate the total amount of Service Fee to be paid out to you for any Bookings completed by you in that period.</p>
        <p><strong>Property</strong> means the location chosen by a Customer for the Service Provider to provide the Service.</p>
        <p><strong>Professional Equipment </strong>means equipment required by you to be able to provide the Services, such equipment includes but not limited to a massage table, massage oils, relaxing music, and clean towels.</p>
        <p><strong>Rebooking Re</strong><strong>quest</strong> means a Direct Booking Request made by a Customer offered to a Specific Service Provider that the Customer has booked before.</p>
        <p><strong>Service Fee</strong> means the amount inclusive of GST displayed on the Platform as being payable by a Customer to the Service Provider, and collected by the Therappy platform on behalf of the Service Provider, on the acceptance of a Booking Request which can be subject to change if the Customer extends or change the Booking Request Details of the Services.</p>
        <p><strong>Services </strong>means the services provided as part of massage therapy, beauty therapy and other therapeutic or allied health treatments, including but not limited to remedial massage, sports massage, Swedish / relaxation massage and pregnancy massage provided by a Service Provider in accordance with these Terms.</p>
        <p><strong>Service Provider</strong> means an individual (including a person trading under a business name), approved by Therappy to use the Platform to offer their services or products to clients.</p>
        <p><strong>Specific Service Provider </strong>means a specific Service Provider a Customer has used before as requested or has been referred to a Customer by another user or admin.</p>
        <p><strong>Support</strong> means the technical support, resources and information, payment and maintenance services for the Platform and updates to the Platform software that Therappy may make generally available to you and the Service Provider.  </p>
        <p><strong>Taxes</strong> means income tax, payroll tax, stamp duty, GST, VAT, sales tax, superannuation, and all other applicable taxes.</p>
        <p><strong>Therappy</strong> or we or us means Therappy Pty Ltd ABN 69 666 183 857.</p>
        <p><strong>User/Member Profile</strong> means a profile created on the Platform by you and includes your name, address, phone number, gender, and credit card details.</p>
        <p><strong>Website/Site</strong> means the website at the domain name <a class="link-text-decorate-none" href="https://getblys.com.au/">https://Therappy.com.au</a> or <a class="link-text-decorate-none" href="https://getblys.com/">https://Therappy.com</a> (or such other domain names as Therappy may use in relation to the Business from time to time).</p>
        
        
        <p class='policy-article'>Provider Standards</p>
        <p class='policy-article'>Treat everyone with Respect </p>
        <p>Therappy believes that everyone should feel respected and supported and as such we have set standards on what is appropriate behaviour. We want users of the THERAPPY platform to experience a community of mutual respect.</p>
        <p>We want to be very clear that any behaviour that is rude, aggressive, or threatening will not be tolerated. </p>
        <p>Therappy expects providers to behave professionally and provide services in a safe and ethical manner, and in line with the Code of Conduct for unregistered health providers.</p>
        <p>Illegal behaviour such as discrimination, inappropriate touching, or sexual assault, will result in your immediate removal from our platform.</p>
        <p class='policy-article'>Clear treatment / service plan explained for every appointment or service</p>
        <ul>
            <li>Treatment / service must be within the registered (booked) service scope (& providers qualification) </li>
            <li>Explicitly detail treatment plan and gain <i>Informed consent</i></li>
            <li>Immediately cease treatment / service if consent is revoked for any reason </li>
        </ul>
        <p class='policy-article'>Understanding Informed Consent</p>
        <ul>
            <li>Provide information about treatment / service to clients in a way they can understand before asking for their consent and prior to any treatment / service starting</li>
            <li>Consent for treatment / service is actively sought and voluntarily and freely given </li>
            <ul>
                <li>Legally (not underage, able to consent, language)</li>
                <li>Consciencely (not asleep/ impaired/understood)</li>
            </ul>
            <li>Consent can be revoked at any stage</li>
        </ul>
        <p class='policy-article'>Zero Tolerance</p>
        <p class="block-ul"><strong>Client safety:</strong></p>
        <ul>
            <li>Do not touch a client's genitalia (not ever) </li>
            <li>Do not touch the breast area regardless of gender (unless explicit written consent with informed therapeutic assessment and agreed treatment plan is obtained prior to commencement)</li>
            <li>Do not demonstrate any behaviour or comment that could make a person feel uncomfortable</li>
            <li>Verbal; comments on appearance, perceived gender identity or sexual orientation, unrelated personal questions, jokes or banter</li>
            <li>Non-verbal; include gestures, winks, inappropriate touch (non-clinical)</li>
            <li>Treatment techniques, including inappropriate draping, disrobing, or equipment use</li>
            <li>No intimate relationships are acceptable within the Therappy interaction</li>
            <li>Consequences for any breach will be enacted immediately</li>
        </ul>
        <p class="block-ul"><strong>Your safety:</strong></p>
        <ul>
            <li>If you are not confident that the client fully understands the treatment or has given informed consent - do not begin the treatment </li>
            <li>Immediately cease treatment in the event a client initiates sexual behaviour</li>
            <li>If at any time you don't feel safe, you should cease treatment and leave</li>
            <li>Report any of these incidents via the THERAPPY platform</li>
            <li>Consequences for any breach will be enacted immediately</li>
        </ul>
        <p class='policy-article'>Other inappropriate conduct that Therappy has Zero Tolerance for</p>
        <ul>
            <li>Aggressive, confronting, and harassing behaviour is not allowed. Do not use any language or make gestures that could be interpreted as disrespectful, rude, or threatening. </li>
            <li>Discrimination. Therappy does not tolerate any behaviour that discriminates against someone based on personal traits, this could include age, disability, gender identity, marital status, national origin, race, religion, political orientation, or sexual orientation. </li>
            <li>Advice or treatments outside your qualification or engaged service </li>
            <li>Ongoing contact - outside the Therappy platform </li>
            <li>Wilful property damage (from either client or provider) is never allowed. Accidental property damage may result in repair/ cleaning fees, all incidents must be reported to Therappy.</li>
        </ul>
    </div>
`;

  return (
    <>
      <Header />
      <Container>
        <Grid sx={{ pt: 12, pb: 5 }} container spacing={1}>
          {!isMobile && (
            <Grid item md={3}>
                <MenuList className={'list-item-policy'}>
                <StyledLink to={PublicRouteEnum.PRIVACY_POLICY}>
                  <MenuItem sx={{ mb: 3 }}>Privacy Policy</MenuItem>
                </StyledLink>

                <StyledLink to={PublicRouteEnum.THERAPPY_PRIVACY_POLICY}>
                  <MenuItem sx={{ mb: 3 }}>Therappy Privacy Policy</MenuItem>
                </StyledLink>

                <StyledLink to={PublicRouteEnum.TERMS_AND_CONDITIONS}>
                  <MenuItem sx={{ mb: 3 }}>Website Terms & Conditions</MenuItem>
                </StyledLink>

                <MenuItem className={'list-item-policy-active'} sx={{ mb: 3, color: colors.primary[70] }}>Service Provider Terms & Conditions</MenuItem>

                <StyledLink to={PublicRouteEnum.CUSTOMER_TERMS_CONDITIONS}>
                  <MenuItem sx={{ mb: 3 }}>Customer Terms & Conditions</MenuItem>
                </StyledLink>

              </MenuList>
            </Grid>
          )}
            {isMobile && (
                <Grid item xs={12}>
                    <MenuList className={'list-item-policy'}>
                        <StyledLink to={PublicRouteEnum.PRIVACY_POLICY}>
                            <MenuItem sx={{ mb: 3, justifySelf: 'center' }}>Privacy Policy</MenuItem>
                        </StyledLink>

                        <StyledLink to={PublicRouteEnum.THERAPPY_PRIVACY_POLICY}>
                            <MenuItem sx={{ m: 0, p: 0, justifySelf: 'center' }}>Therappy Privacy Policy</MenuItem>
                        </StyledLink>

                        <StyledLink to={PublicRouteEnum.TERMS_AND_CONDITIONS}>
                            <MenuItem sx={{ m: 0, p: 0, justifySelf: 'center' }}>Website Terms & Conditions</MenuItem>
                        </StyledLink>

                        <MenuItem className={'list-item-policy-active'} sx={{ mb: 3, color: colors.primary[70], justifySelf: 'center' }}>Service Provider Terms & Conditions</MenuItem>

                        <StyledLink to={PublicRouteEnum.CUSTOMER_TERMS_CONDITIONS}>
                            <MenuItem sx={{ m: 0, p: 0, justifySelf: 'center' }}>Customer Terms & Conditions</MenuItem>
                        </StyledLink>
                    </MenuList>
                </Grid>
            )}
          <Grid item xs={12} md={9} lg={8} xl={7}>
            <div dangerouslySetInnerHTML={{__html: htmlContent}}/>
          </Grid>
        </Grid>
      </Container>
      <Footer/>
    </>
  );
};

export default ServiceProviderTermsConditions;
