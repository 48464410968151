import {ReactElement, useContext} from 'react';
import {Box, IconButton, styled, Theme, useMediaQuery, useTheme} from '@mui/material';
import {colors} from '../../../config/theme/colors';
import Button from '../../ui/button';
import {ClientRouteEnum} from '../../../modules/client/routes/enums/route.enum';
import {ProviderRouteEnum} from '../../../modules/provider/routes/enums/route.enum';
import {useDispatch, useSelector} from "react-redux";
import {selectAuth} from "../../../store/selectors";
import {getImagePath} from "../../../helpers/image.helper";
import {ImageSizesEnum} from "../../../enums/image-sizes.enum";
import {shortString} from "../../../helpers/string.helper";
import Logout from "../../ui/icon/logout";
import useIsMobile from "../../../hooks/use-is-mobile.hook";
import {logout} from "../../../store/actions/auth";
import {useConfirmation} from "../../../hooks";
import {useNavigate} from "react-router-dom";
import {PublicRouteEnum} from "../../../modules/public/routes/enums/route.enum";

const HeaderContainer = styled(Box)(
    () => `
  height:82px;
  position: fixed;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0px 16px;
  justify-content: space-between;
  background: ${colors.background.BG_1};
  z-index: 9999;
`,
);

const Links = styled(Box)(
    ({theme}) => `
  display: flex;
  width: 100%;
  height: 100%;
  align-content: center;
  align-self: center;
  align-items: center;
  
  @media (min-width: ${theme.breakpoints.values.xl}px) {
    width: 100%
  }
  
`,
);

const Link = styled('div')(() => `
  color: #595A70;
  font-size: 14px;
  font-weight: 600;
  line-height: 82px;
  width: 100%;
  margin-left: 2px;
  margin-right: 2px;
  min-width: 80px;
  text-decoration: none;
  cursor: pointer;
  height: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-self: center;
  align-items: center;
  text-align: center;
  
  &:hover {
    background-color: #F0F7FF;
    color: #088CEF;
  }
`);

enum Test {
    ONE = 'one',
    TWO = 'two',
}

const UserInfo = styled(Box)(
    ({theme}) => `
    display: flex;
    position: relative;
    padding-left: 40px;
    bottom: 0;
    flex-wrap: none;
    max-width: 270px;
    
     @media (max-width: ${theme.breakpoints.values.lg}px) {
      padding-left: 15px;
    }
    
     @media (max-width: ${theme.breakpoints.values.md}px) {
       padding-left: 0px;
    }
`,
);

const UserAvatar = styled('img')(
    () => `
    width: 40px;
    height: 40px;
    border-radius: 40px;
    object-fit: cover;
    margin: auto;
`,
);

const UserName = styled('p')(
    () => `
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    color: #595A70;
    margin: 0;
    text-wrap: wrap;
`,
);

const UserEmail = styled('p')(
    ({theme}) => `
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
    color: #595A70;
`,
);

const LogoutIcon = styled(Logout)(
    () => `
  stroke: #595A70;
`,
);

const DesktopHeader = (): ReactElement => {
    const scrollToSection = (to: string) => document.getElementById(to)?.scrollIntoView({behavior: 'smooth'});
    const openLoginPage = () => window.location.href = ProviderRouteEnum.SIGN_IN;
    const {authUser} = useSelector(selectAuth);
    const isMobile = useIsMobile();
    const dispatch = useDispatch();
    const {Confirmation, showConfirmation} = useConfirmation();
    const theme = useTheme<Theme>();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));
    const navigate = useNavigate();

    const redirectToPage = (to: string) => {
        navigate(PublicRouteEnum.LANDING);
        setTimeout(() => scrollToSection(to), 100);
    }

    const handleLogout = () => {
        console.log('handleLogout')
        showConfirmation({
            text: 'Confirm logout',
            description: 'Are you sure you want to log out?',
            onConfirm: () => {
                dispatch(logout());
            },
        });
    };

    return (
        <>
            {Confirmation}
            <HeaderContainer>
                <div style={{display: 'flex', width: '100%', maxWidth: '1440px', margin: 'auto', justifyContent: 'space-between'}}>
                    <div style={{display: 'flex'}}>
                        <img style={{maxWidth: '95%'}} src="/img/logo.svg"/>
                    </div>
                    <Links>
                        <Link onClick={() => redirectToPage('services')}>Services</Link>
                        <Link onClick={() => redirectToPage('why_therappy')}>Benefits</Link>
                        <Link sx={{minWidth: '110px'}} onClick={() => redirectToPage('advantages')}>How we work</Link>
                        <Link sx={{minWidth: '120px'}} onClick={() => redirectToPage('our_professionals')}>Our Therapists</Link>
                        <Link sx={{minWidth: '120px'}} onClick={() => openLoginPage()}>Therapist Login</Link>
                        <Link>Contacts</Link>
                        {authUser ?
                            <UserInfo>
                                <UserAvatar src={getImagePath(authUser.image_id || 0, ImageSizesEnum.THUMBNAIL)}/>
                                <>
                                    <Box sx={{ml: 1, display: 'grid', overflow: 'hidden'}}>
                                        <UserName>
                                            {shortString(authUser.first_name, 18)} {shortString(authUser.last_name, 18)}
                                        </UserName>
                                        <UserEmail>{shortString(authUser.email, 30)}</UserEmail>
                                    </Box>
                                    {!isMobile && (
                                        <IconButton onClick={handleLogout}>
                                            <LogoutIcon/>
                                        </IconButton>
                                    )}
                                </>
                            </UserInfo> :
                            <Box sx={{display: 'flex'}}>
                                <Button
                                    sx={{mr: 2, color: colors.primary[50], ...(isSmallScreen && {width: '90px'})}}
                                    onClick={() => (window.location.href = ClientRouteEnum.SIGN_IN)}
                                >
                                    Login
                                </Button>
                                <Button
                                    sx={{background: colors.primary[50], ...(isSmallScreen && {width: '90px'})}}
                                    onClick={() => (window.location.href = ClientRouteEnum.SIGN_UP)}
                                    variant="contained"
                                >
                                    Sign Up
                                </Button>
                            </Box>
                        }
                    </Links>
                </div>
            </HeaderContainer>
        </>
    );
};

export default DesktopHeader;
