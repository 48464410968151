import React, { ReactElement } from 'react';
import Header from '../../../../common/layouts/header';
import Footer from '../../../../common/layouts/footer';
import { Container, Grid, MenuItem, MenuList, styled, Typography } from "@mui/material";
import useIsMobile from '../../../../hooks/use-is-mobile.hook';
import { colors } from '../../../../config/theme/colors';
import { PublicRouteEnum } from '../../routes/enums/route.enum';
import { Link } from "react-router-dom";

const StyledLink = styled(Link)(
  () => `
  margin-top: 4px;
  text-decoration: none;
  color: ${colors.secondary[90]};
  font-size: 14px;
`,
);

const CustomerTermsConditions = (): ReactElement => {
  const isMobile = useIsMobile();
    const htmlContent = `
    <div class="wrap-text-policy-pages">
      <h1>Customer Terms & Conditions</h1>
      <p>Thank you for using Therappy and welcome the terms and conditions governing how we work with you and our extensive network of Service Providers.  </p>
      
      <p>We have included the following terms and conditions for use of our website and platform. We will do our best to keep things running smoothly for you and our Service Providers. The following terms are your obligations and responsibilities when using or accessing the Therappy platform, website, and app ("Platform"). These terms and conditions constitute a legally binding agreement between you and Therappy ("Agreement"). You must agree to these terms to access and use Therappy.</p>
      
      <p class='policy-article'>Our Mission</p>
      <p>At Therappy our mission to make the experience of getting and having therapy that comes to you easier. The aim of our Platform is to help you, a member, our 'Customer' makes a seamless 'Booking Request' for a specific type or treatment, or therapy also known as 'Service' online via the Platform. A member is someone, who has an account on our Platform 'Member.'</p>
      
      <p>We 'Therappy' will then do our very best to connect your Booking Request to one of our highly skilled therapists or healthcare professionals also known as a 'Service Provider' who will then attend to you in the comfort of your own home or preferred location or 'Property.'</p>
      
      <p>Please read and understand all the terms, policies, Customer Code of Conduct and Privacy Policy carefully and ensure you agree with these before using the Therappy Platform.</p>
      
      <p>If you do not agree with any of the terms, policies, or standards then you cannot use or make any bookings on the Platform.</p>
      
      <p class='policy-article'>About Us</p>
      <p>Therappy is healthcare technology platform that connects you to our extensive network of healthcare providers or therapists 'Service Providers.'</p>
      
      <p>Therappy brings an extensive network of Service Providers to one place online making it easier for you to search, book and request a Service that comes to you.</p>
      
      <p>Each Service Provider is strictly vetted for:</p>
      <li>their proven qualifications and skill set;</li>
      <li>their years of experience in that Service;</li>
      <li>their background - e.g. National Police Clearance, Working with Children Clearance, and Professional referees; and</li>
      <li>their professional license to practice in their profession.</li>
      
      <p>Once accepted onto the Therappy platform, Service Providers go through a strict induction, where they agree to accept a licence agreement with Therappy, which enables them to access the Therapist App which communicates all the Booking Requests received within a specific location, profession, specialisation, and their hours of availability.</p>
      
      <p>This enables the Therappy Platform to connect your Booking Request with an available Service Provider who is suitably matched, qualified, and available at your preferred time and date.</p>
      
      
      <p class='policy-article-upper'>FACILIATOR ONLY</p>
      <p>Therappy is not party to any Agreement made between you and the Service Provider.</p>
      <p>The App and Platform provide a Booking and third-party payment service by which users can make a booking request for a service from Service Providers to perform a Service. You should exercise common sense and caution to protect your and others' personal property and personal safety. You agree that it is your responsibility to take reasonable precautions in all actions and interactions with any third party you interact with in or through the Service.</p>
      <p class='policy-article-upper'>WARNING: MEDICAL TREATMENTS AND SERVICES VIA THE PLATFORM</p>
      
      <p>Therappy does provide a Service as such and does not endorse any Therapist or any Product, medication, information that is made available to you by using the Platform. Therappy does not guarantee the appropriateness or effectiveness of Therapist Services that are provided to you by any Service Provider. Therappy is not responsible for, ad bears no liability with respect to, any medical or non-medical information provided by a Service Provider.</p>
      
      <p>It is solely the responsibility of each Customer and not Therappy to determine whether a given Service Provider is qualified and capable of rendering Services. Further, Therappy does not and cannot guarantee its verification processes. Therappy disclaims any liability arising out of Therappy's failure to accurately verify the identity of a Therappy Customer or Provider.</p>
      <p>You acknowledge that your reliance on any information provided by the Service or by any provider to you is used solely at your own risk and you assume full responsibility for all risk associated therewith, as allowable to the extent of the law. The Services are not for medical emergencies. If you think you have an emergency, call 000 immediately.</p>
      <p class='policy-article-upper'>NON-SOLICITATION</p>
      <p>You further acknowledge and agree to not engage any Service Provider for paid services outside of this Agreement while a Customer of Therappy for up to 12 months after termination of this Agreement.</p>
      <p class='policy-article'>License</p>
      <p>Subject to your compliance with this Agreement, Therappy grants you a limited, non-exclusive, non-sublicensable, revocable, non-transferrable licence to:</p>
      <li>access and use the App and Platform on your personal device solely in connection with your use of the Services; and</li>
      <li>access and use any content, information and related materials that may be made available through the Services, in each case solely for your personal, non-commercial use.</li>
      
      <p>Any rights not expressly granted herein are reserved by Therappy and Therappy's licensors.</p>
      <p>Therappy may revoke, suspend, or terminate this licence in its absolute discretion without any notification or reason for any for breach of this Agreement by a Customer or any unfit conduct, or use of its Services. Therappy may also terminate any Member's account if Member's ratings are not considered acceptable.</p>
      
      <p>Your licence to access the App and Platform shall be ongoing unless terminated by Therappy in accordance with this Agreement.</p>
      
      
      <p class='policy-article'>Eligibility to use Therappy Platform</p>
      
      <p class='policy-article'>Customer Code of Conduct</p>
      <p>Therappy welcomes and supports people from all backgrounds and identities. It is our aim is to deliver a healthy, safe, inclusive, and free of discrimination environment for both our clients and Service Providers.</p>
      <p>To uphold this ethos, we have a basic code of conduct in place. This code is applicable to anyone using our platform, website, and any form of communication on our platform.</p>
      <p>We ask our customers to be considerate, embrace this code. Any breach of this code is taken seriously. Any breach of this code should be immediately reported to help@therappy.com.au immediately.</p>
      <p>When using our platform, we ask you to please respect our code of conduct to ensure a healthy, safe environment and experience.</p>
      <p>• Be friendly and patient.</p>
      <p>• Be inclusive.</p>
      <p>• Be considerate.</p>
      <p>• Be respectful. We understand disagreements happen. Please remember to remain calm and be respectful when resolving any disagreements. Please contact us immediately to resolve any issues help@therappy.com.au. By using the Therappy Platform you acknowledge, warrant, and represent</p>
      
      <ul>
          <li>you are an individual and you are at least 18 years of age;</li>
          <li>you have the authority and capacity to consent and enter into this Agreement;</li>
          <li>any information provided by you is true, correct and not misleading and you will update all information;</li>
          <li>you will act in good faith and not misuse the Services so as to cause harm or damage to Therappy, a Service Provider or another Customer;</li>
          <li>you will comply all Therappy's policies available on the Therappy Site; and</li>
          <li> you abide by Therappy's Code of Conduct below.</li>
      </ul>
      
      <p>If you are an organisation, company, or other entity, you also warrant that you:</p>
      <ul>
          <li>are validly incorporated; and</li>
          <li>have full power, authority and corporate approvals required to enter into this Agreement; for example, you are an aged care facility that allows Service Providers to undertake therapy with a resident, or alternatively, a school that allows Service Providers to undertake therapy with a child attending school.</li>
      </ul>
      <p>You agree that neither you nor any other person at your home, Property where the Service Provider will meet you:</p>
      <ul>
          <li>has been convicted of any serious criminal offence, or any criminal offence involving violence, assault, fraud or dishonesty, any sexual offence, or any offence relating to the safety of children or vulnerable persons, any offence relating to workplace health and safety, or any offence which may reasonably be considered relevant to the provision or receipt of Services</li>
          <li>is presently the subject of a complaint, claim, investigation, or other legal action, charge, proceedings, or other litigation in relation to any criminal conduct;</li>
          <li>has ever been the subject of Investigation or legal action in relation to any criminal conduct that has not been notified in writing to Therappy; or</li>
          <li>has been and/or is currently required to register as a sex offender in any jurisdiction or with any governmental entity.</li>
      </ul>
      <p class='policy-article'>Term of this Agreement</p>
      <p>This Agreement commences on the date you access and use the Therappy Platform. Therappy may, from time to time, without notice update the terms and conditions of this Agreement. We recommend you regularly visit our website <a class="link-text-decorate-none" href="http://www.Therappy/legal">www.Therappy/legal</a> for updates or changes made to the Agreement to which you are bound. This Agreement remains in force unless and until terminated in accordance with the terms and conditions in this Agreement.</p>
      
      <p class='policy-article'>Information storage</p>
      <p>Therappy allows you to store certain parts of your health information (such as your medical history or current issues, pain, or concerns) on the Platform.</p>
      
      <p class='policy-article'>No guarantees</p>
      <p>Therappy does not endorse any Service Provider, or any product, medication, information that is made available to you by using the Platform. Therappy is unable to guarantee, and has no control over, the professional qualifications of the Service Provider, their expertise, quality of advice and Services, or any other information made accessible through the Services whether on the Site or provided directly by a therapist. Therappy will request all Service Providers to upload and submit proof of professional registration and qualifications, but we do not give any warranty of any Service Providers qualifications and experience. Therappy does not guarantee the appropriateness or effectiveness of Services that are provided to you by a Service Provider.</p>
      
      <p class='policy-article'>No advice</p>
      <p>Therappy is not responsible for, and bears no liability with respect to, any medical or non-medical information provided by a Service Provider or other professional.</p>
      
      <p class='policy-article'>No relationship</p>
      <p>You understand that information may be provided to you by Service Providers in the medical profession, but this does not create a medical-patient/client relationship (or other variations of this type of relationship) between you and Therappy. Therappy is not a health professional or medical professional, and no such relationship is formed with you. We do not have a fiduciary duty to any person using the Services.</p>
      
      <p class='policy-article'>No monitoring</p>
      <p>Therappy has no control over, and no duty to monitor, the way in which you use the Platform or Services, what information you access, and the consequences of the information may have on you or others.</p>
      
      <p class='policy-article'>Bookings</p>
      <p>You acknowledge and agree:</p>
      <ul>
          <li>Therappy is a Platform that connects you to the Service Providers for the sole purpose of enabling you to make a Booking Request with the Service Providers that travel to you to deliver Services;</li>
          <li>to use Therappy solely for the purpose of making Booking Requests and for no other purposes;</li>
          <li>to not use any speculative, false, or fraudulent Booking Requests;</li>
          <li>to only make bookings for yourself or another person for whom you are legally authorised to act;</li>
          <li>that any abuse of the Booking System will result in you being denied access to the Platform.</li>
      </ul>
      <p class='policy-article'>Facilitator Only</p>
      <p>You further acknowledge and agree that:</p>
      <ul>
          <li>Therappy does not provide, perform, or conduct any Services nor does it act as an agent or broker for any Service Provider;  </li>
          <li>we do not employ the Service Providers registered on the Therappy Platform;</li>
          <li>we do not participate in the interaction between you and the Service Provider;</li>
          <li>we are not a party to any agreement made between you and the Service Provider;</li>
          <li>all services on Therappy Platform are strictly professional and therapeutic. Any sexual suggestions or comments by you will result in termination of your Booking with no refund and will be reported to authorities as a matter of sexual harassment. You will be prohibited from using the Platform for any future Booking Requests; and</li>
          <li>we have no control the Service Providers professional conduct, actions, or omissions of the or any other users of the Platform.</li>
      </ul>
      <p>You acknowledge and agree you are not permitted to engage the Service Provider in any other form of employment outside of the Platform.</p>
      
      <p class='policy-article'>Personal Information and Member Profile</p>
      <p>To make Booking Requests on the Therappy Platform you must create a Member profile. You will be asked to create a personal username and password which will be linked to your profile.</p>
      <p>You acknowledge and agree:</p>
      <ul>
          <li>that you will not transfer or share your account details with any other person;</li>
          <li>not to register for more than one Member profile;</li>
          <li>that you are solely responsible for all, or any information uploaded onto the Platform relating to your profile and payments due for Services booked via your profile/account.</li>
      </ul>
      <p>Therappy will ask you to verify your personal email before allowing you to complete a Booking Request. You must notify Therappy immediately if you detect any breach of security of your username and password. Therappy may, without notice to you, and at our sole discretion. delete or block your Member profile. Any blocked or deleted Members or Guests must not create a new account.</p>
      
      
      
      <p class='policy-article'>Booking System</p>
      <p>Therappy will ask you to verify your personal email before allowing you to complete a Booking Request. Once you have confirmed your Booking Request, verified your username and email address the Platform will send out your Booking Request via the App to all Service Providers, who meet the eligibility requirements; fulfilling your Booking Request is subject to the availability of Service Providers at the specific time, date, and location of your request. You can also make a Direct Booking Request i.e. of a specific Service Provider.</p>
      
      <p>When a Service Provider accepts your Booking Request:</p>
      <ul>
          <li>Therappy will send you a text and email notification to your email address and/or Therappy Member Hub inbox;</li>
          <li>Therappy will then deduct payment (the Service Fee and Platform Fee) from your registered preferred nominated Payment Method and will send a tax invoice to your email address and Therappy Member Hub Inbox; and</li>
          <li>if the Service Provider before the confirmed Booking cancels your Booking Request, the   above amounts will be refunded to you.</li>
      </ul>
      <p class='policy-article'>Service Fees</p>
      <p>After the completion of the Services to you by a Service Provider and regardless of whether or not you dispute the amount of the fees, or the quality or nature of the Services provided. Therappy will release the Service Fee to the Service Provider. You agree to pay to Therappy all Fees, whether or not you dispute the amount of the charge, or the quality or nature of the services provided.</p>
      <p><strong>Payment processing Services</strong> Therappy will use a third-party payment processing Platform service to securely hold your bank account details and process payments.  Therappy will not be held responsible for all and any liability for any claims or damages related to Therappy's use of third-party payment processing services and such transfers, including without limitation, any damage that may result should any such information be released to any third parties.</p>
      <p>Payment processing services for you on our Platform is provided by Stripe and are subject to the Stripe Connected Account Agreement, which includes the Stripe Terms of Service (collectively, the "Stripe Services Agreement"). By agreeing to this Agreement or continuing to use the Platform, you agree to be bound by the Stripe Services Agreement, as the same may be modified by Stripe from time to time. As a condition of our enabling payment processing services through Stripe, you agree to provide us with accurate and complete information about you, and you authorise us to share it and transaction information related to your use of the payment processing services provided by Stripe.</p>
      <p><strong>Authorisation to collect payment:</strong><i> </i></p>
      <p>You agree for Therappy to collect payment via direct debit from your nominated bank account or credit card or other method agreed with Therappy for any Service Providers fee, the Platform Fee or any other fees payable to Therappy. </p>
      
      <p>You are solely responsible for the accuracy of the bank account or credit card details provided to Therappy.  Therappy is not liable for any funds incorrectly deposited or not deposited due to inaccurate or incomplete bank details or credit card details provided by a Service Provider.</p>
      <p>If your existing nominated bank account or credit card is cancelled, suspended or is otherwise not usable, you must immediately provide Therappy with details for an alternative bank account or credit card which can be used to meet your obligations under this Agreement.</p>
      
      <p><strong>Service Fees</strong> The Service Fee is the amount specified on the Platform for a Service and is determined by:</p>
      <ul>
          <li>the type of Service;</li>
          <li>if it is an Initial or Follow up Service;</li>
          <li>the duration of Service;</li>
          <li>any out of service area travel costs;</li>
          <li>the service fee will include the Platform Fee.</li>
      </ul>
      <p>The fees listed on the Service are the standard fees for the listed service, which Therappy may change at any time in its sole discretion. In addition, fees for services provided by a Service Provider may vary depending on a variety of factors such as length, day, time, or location of appointment. The fee you are ultimately charged is the fee, and Therappy is under no obligation to advise you of any changes to Service Fees or Platform Fees.</p>
      
      <p class='policy-article'>Insurance and Medicare</p>
      <p>You acknowledge that Services obtained will in all likelihood may not be covered services under any private health insurance plan, Medicare, or any health insurance plan whether NDIS government-sponsored or private.</p>
      
      <p>You are solely responsible for checking with any health insurer before placing a Booking Request, in relation to any reimbursement. Therappy will have no liability in connection with your ability to secure reimbursement for any such Service.</p>
      
      <p>You acknowledge and agree that you are solely responsible for making any claims with your insurance benefit plan, Medicare, or any other third-party medical benefits program in relation to the Services you receive using the Therappy Platform.</p>
      
      <p class='policy-article'>Data charges</p>
      <p>You acknowledge, understand, and agree that when using the Service, you may be responsible for standard phone, data, and messaging charges from your wireless carrier. Under no circumstances will Therappy be responsible for any wireless email, data, phone charges.</p>
      
      <p class='policy-article'>Payment agreements</p>
      <p>At the time of confirming a Booking Request you agree to provide Therappy with your preferred Payment Method to be held securely on the payment gateway. You consent to payment being deducted from the payment details you registered on the Therappy Platform.</p>
      <p>At the time of confirming a payment to Therappy, you must agree to ensure that sufficient funds are available for debit. Any fees and charges relating to insufficient funds at time of debit will be your responsibility. You acknowledge and agree that Therappy will hold the Service Fee on behalf of the Service Provider on a third-party integration until the the Service Provider has selected 'service completed' on the Platform. Once a Service Provider has submitted a 'service completed' notification via the App, your payment will be released to the Service Provider.</p>
      
      <p>You acknowledge and agree when making a recurring Booking, Therappy will take the initial Platform Fee immediately, and 72 hours prior to the recurring Booking date. It is your responsibility to ensure sufficient funds are available to process payment. Therappy will generally refund any Service and or Platform fees within 48 hours of the refund occurring.</p>
      
      
      <p class='policy-article'>Cancellation and refunds</p>
      <p>At Therappy we understand that life happens, and you may need to cancel or change your booking requests. You must read and agree to the following conditions for a refund related to a change and or cancellation.</p>
      
      <p>If a Service Provider cancels a confirmed booking:</p>
     <ul>
      <li>If a Service Provider cancels or Abandons in the lead up to the time of the appointment booking, Therappy will send a notification out to all other available Service Providers available at that time in an attempt to find another Service Provider to complete your Booking Request;</li>
      <li>If Therappy is unable to match you to an alternative Service Provider, you will be offered the opportunity to rebook your credit for an alternative date and time or opt for a refund Therappy will refund you the Service Fee for the Service you requested.</li>
     </ul>
      <p>If a Service Provider declines Booking Request or Rebooking Request:</p>
      <ul>
          <li>If Therappy is unable to match your Booking Request to a Service Provider, Therappy will offer the Booking Request out to all Service Provider again via the Therapist App;</li>
          <li>Therappy will notify you by text and email to both your email address and Therappy Member Hub Inbox if your Booking Request is not able to be fulfilled; and</li>
          <li>You will be offered the opportunity to change your preferred date and or time;</li>
      </ul>
      <p>If we cannot match your request, you will receive a refund of the Service Fee and the Platform Fee if a new booking date and time cannot be confirmed by another Service Provider on the Platform.</p>
      
      <p>You will be eligible for a full refund if you provide Therappy notice of the change or cancellation request 24hours before a Booking Request start time.</p>
      
      <p class='policy-article'>Fees for cancellations and changes:</p>
      <p>You agree and acknowledge that Therappy will charge you a cancellation fee equivalent of (50%) of Service Fee if you cancel or amend the Booking Request 4 hours prior to the Booking start time.</p>
      
      <p>You agree and acknowledge that Therappy will charge you a cancellation fee equivalent</p>
      <p>to (100%) of Service Fee if you cancel or amend the Booking less than four (4) hours before the scheduled Booking start time, or if the Service Provider has sent a notification via the Therapist App to you that they 'on their way' to meet you at the agreed booking start time.</p>
      
      <p class='policy-article'>Late and No Show Fees</p>
      <p>You acknowledge and agree that if you are not at your property or the requested meeting location or are running 20 minutes lates after the agreed start time, you will be liable for the full Fee and no refunds with be payable.</p>
      
      <p class='policy-article'>Other charges and fees</p>
      <p>Therappy will keep 100% of the Platform Fee for any booking if you cancel after the Service Provider has accepted your Booking Request.</p>
      
      <p>Therappy will charge 100 % of the Service Fee if the Therapist reports you have not complied with our conduct rules.</p>
      
      <p class='policy-article'>Services</p>
      <p>Any issues regarding safety, quality, condition, or description may be sent to Therappy through the Platform and passed onto the Service Provider that completed the Booking.</p>
      
      <p class='policy-article'>Termination of your Therappy Account</p>
    <ul>
        <li>Therappy  may without notification, at its discretion terminate your access to and use of the Therappy Site and Platform.</li>
        <li>If your access to the Site and Platform has been terminated, you will no longer have access to your Member Account as it will be blocked,</li>
        <li>Any licences granted to you and all disclaimers of Therappy and limitations of Therappy's liability set out in this Agreement or elsewhere on the Platforms will survive termination</li>
    </ul>
    <p> You may terminate this Agreement at any time by emailing Therappy at help@Therappy.com.au requesting termination of your Member or Guest Profile. You agree Therappy reserves the right to deduct any outstanding fees and charges owing to Therappy and/or the Service Provider(s)on your Member Account prior to disabling your profile.</p>
    
    <p class='policy-article'>Dispute Resolution</p>
    <p>You acknowledge and agree that:</p>
    <ul>
        <li>Therappy  is not party to any dispute between you and the Service Provider;</li>
        <li>Therappy will not be held responsible for any dispute between you and a Service Provider, nor is under any obligation to mediate any dispute that may arise between you and a Service Provider;</li>
        <li>you will be required to pay or reimburse any legal fees incurred by Therappy as a result of having to mediate any dispute between you and a Service Provider.</li>
        <li>if a dispute arises between you and Therappy out of this Agreement, our goal is to address your concerns and, if we are unable to do so to your satisfaction, to provide you with a means of resolving the dispute quickly.</li>
    </ul>
    <p>For any claim against Therappy:</p>
    <ul>
    <li>either party may elect to resolve the dispute in a cost-effective manner through a binding non-appearance-based mediation.</li>
    <li>in the event of an unsuccessful mediation, and in circumstances where both parties to any dispute agree in writing, arbitration may be initiated through an established alternative dispute resolution (<strong>ADR</strong>) provider in New South Wales Australia, which is to be selected by you from a panel of established ADR providers that Therappy will provide to You.</li>
    <li>The ADR provider and the parties must comply with the following rules:</li>
    </ul>
    <ul>
      <ul>
        <ul>
          <li>the arbitration shall be conducted by telephone, online and/or be solely based on written submissions, the specific manner shall be chosen by the party initiating the arbitration;</li>
          <li>the arbitration shall not involve any personal appearance by the parties or witnesses unless otherwise mutually agreed by the parties.</li>
        </ul>
      </ul>
      <li>If the parties elect arbitration under this clause you must not commence proceedings until the arbitration has been determined, provided that nothing in this clause prevents any party from seeking urgent injunctive relief.  This Agreement may be pleaded in bar to any proceeding commenced in breach of this clause.</li>
      <li> Therappy may recover its legal fees and costs (including on an indemnity basis) arising out of any breach of this clause, provided that Therappy has notified you in writing and you have failed to promptly withdraw that claim.</li>
      <li>If any proceeding by or against you is commenced under any provision of any bankruptcy or insolvency law, Therappy will be entitled to recover all reasonable costs or expenses (including reasonable legal fees and expenses) incurred in connection with the enforcement of this Agreement. </li>
    </ul>
    
    <p class='policy-article'>Privacy</p>
    <p>Our collection and use of personal information in connection with the Therappy is as provided in Therappy's Privacy Policy at <a class="link-text-decorate-none" href="http://www.therappy.com.au/privacy">www.therappy.com.au/privacy</a>. Therappy collects and stores Personal Information you enter into the Member Profile. You acknowledge and agree that Therappy has consent to disclose your personal information, including your name and address to the Service Provider to enable the Service Provider to contact you and complete the Booking.</p>
    
    <p class='policy-article'>Reviews</p>
    <p>After each Service, the Service Provider and Customer will have an opportunity to review each other. Your Review must be accurate and may not contain any discriminatory, offensive, defamatory, or other language that violates our content policy below.  Reviews are not verified by Therappy for accuracy and may be incorrect or misleading. </p>
    <p class='policy-article'>How Ratings Work:</p>
    <p>After every treatment session, both clients and service providers can rate each other on a scale from 1 to 5.</p>
    <p>This system helps us maintain high standards of excellence and safety while ensuring everyone has a safe and enjoyable experience.</p>
    <p>Ratings go from 1 to 5, where 5 means awesome and 1 means very poor. When you rate a 5, we will re-match you the next time you make a booking. But if you rate a 2 or below, we ensure you're not re-matched with the same Service Provider.</p>
    <p>And don't forget, if you're ever not happy with a client, let us know - we're here to help! Send us a message at help@therappy.com.au.</p>
    
    <p class='policy-article'>Intellectual Property</p>
    <p>Therappy's Site and Platform may be updated, relocated, or otherwise modified from time to time, including through networks, embeddable widgets, downloadable software, and tablet computer applications, and all intellectual property contained therein.</p>
    
    <p>Therappy claims no ownership over the data or other material you provide to Therappy, and such data will be used in accordance with the Therappy's Privacy Policy in place from time to time.</p>
    
    <p>You acknowledge and agree that the Therappy owns all Intellectual Property Rights in the Site and Platform and no right, title, or interest in any of the Site or Platform is transferred or granted to you, except so far as expressly stated in this Agreement. For the avoidance of doubt, you acknowledge that the payment of the Fee entitles you to be provided with the licence to use the Site and Platform on the terms set out herein. It does not entitle you to ownership of Therappy's intellectual property.</p>
    <p>You agree that:</p>
    <ul>
        <li> you will not copy, reproduce, alter, modify or create derivative works from the Site and Platform;</li>
        <li>if you add any data to Therappy of any nature whatsoever that information is personally attributable to you, and you warrant that you have the right to distribute that information;</li>
        <li> you acknowledge that when you input information into Therappy Site and Platform, we may receive additional related data, such as the time, date and place you provided the information;</li>
        <li>the Therappy logo and name is owned by us and may not be used as part of your business or on the publication by you without the prior written consent of Therappy.</li>
    </ul>
    <p class='policy-article'>Content and your use of the Site</p>
    <p class='policy-article'>Prohibited Content</p>
    <p>The following content is prohibited on our Platform; content of any kind that:</p>
    <ul>
        <li>is offensive, defamatory, distressing, harmful, insulting, intimidating, menacing, harassing, discriminatory, unlawful, false, or misleading, content of any kind;</li>
        <li>poses, or may pose, a risk to any person;</li>
        <li>threatens, harasses, humiliates, or ridicules any person, or is otherwise unlawful, or encourages any such activity;</li>
        <li>is posted, uploaded, published, or disseminated without the lawful owner's permission, or breaches any obligation of confidentiality;</li>
        <li>contains any false or misleading statements, representations, or advertising, (including but not limited to representations about the Member's identity, qualifications, experience, or reputation);</li>
        <li>advertises or promotes goods or services other than the Services;</li>
        <li>is personal information about a person and is posted, uploaded, published, or disseminated without that person's prior consent or in breach of any privacy law, or is contrary to Therappy's Privacy Policy;</li>
        <li>infringes another person's copyright, trademark, or any other intellectual property right;</li>
        <li>includes a person's account credentials or is directed to accessing a person's Member Profile other than in accordance with this Agreement or without the account holder's consent;</li>
        <li>may cause Therappy to breach any law or otherwise expose Therappy to liability;</li>
        <li>is or includes any virus, worm, trojan, or other malicious or unauthorised code or disabling feature to or via the Site or the Services, or which may breach the requirements or cause the loss of services of Therappy's internet service providers or other suppliers;</li>
        <li>is likely to interfere with or disrupt the proper and intended operation of the Site or the Services, or is, or contains any means of automatically searching or mining data from the Site; or</li>
        <li>is contrary to any of Therappy's policies or code of conduct.</li>
    </ul>
    <p>This content is defined as "Prohibited Content."</p>
    
    <p class='policy-article'>Content posted, created, or sourced by/from others</p>
    <p>When you access the Site and/or Services, you may obtain access to content posted by other Members or third parties.  You agree and acknowledge that:</p>
    <ul>
        <li>Therappy provides a platform only, that acts as a forum for venue for the online distribution and publication of content, and Therappy does not verify that content;</li>
        <li> Therappy makes no warranties or representations (either express or implied) whatsoever in relation to the content, its accuracy, completeness, quality, currency or otherwise, and is not responsible for any content;</li>
        <li> the content posted by others may include Prohibited Content;</li>
        <li>Therappy may, but is not obliged to, take any action it considers (in its sole and absolute discretion) appropriate with respect to content that it considers is Prohibited Content, may create liability for Therappy, harm Therappy's business operation or reputation, cause Therappy to lose the services of its suppliers; and</li>
        <li>Therappy reserves the right to reject or modify Content, at its discretion.</li>
    </ul>
    <p class='policy-article'>Content you post</p>
    <p>You are solely responsible for all content that you post, email, or otherwise make available on the Site and/or Services. </p>
    <p>You must agree:</p>
    <ul>
        <li>to grant Therappy a non-exclusive, royalty-free, fully paid, worldwide perpetual license that is capable of being sub-licensed, in respect of all of your copyright and other intellectual property rights related to content that you post or contribute to the Site;</li>
        <li>agree that any such content or any derivative works thereof, may be disseminated, distributed, publicly displayed, reproduced, used, sublicensed, posted, or published by Therappy, and searched, displayed, printed, or otherwise used or exploited by our Guests and Members;</li>
        <li>not to post or upload any Prohibited Content; and</li>
        <li>that none of the content infringes the rights (including but not limited to copyright and other intellectual property rights) of any person.</li>
    </ul>
    <p class='policy-article'>Feedback, Reputation and Reviews</p>
    <p>By using Therappy website and posting feedback and/or reviews ("<strong>User Feedback</strong>"), you grant Therappy an unrestricted, worldwide, royalty-free, sub-licensable licence to use, reproduce, publish, communicate to the public, modify, and adapt your User Feedback posted on the Site for the purpose of publishing that User Feedback on the Site and as otherwise permitted by this Agreement.</p>
    
    <p>You may use any User Feedback that is about you provided that you use it in a way that is not misleading or deceptive and is in accordance with our policies as posted on the Site from time to time.</p>
    
    <p class='policy-article'>Indemnities given by Members</p>
    <p> You agree to indemnify, defend and hold Therappy and its Affiliates harmless from and against any and all third party claims, liabilities, demands, proceedings, losses, costs, expenses, fees (including reasonable attorneys' fees and court costs) and damages (actual, special and consequential) of every kind and nature, known and unknown, incurred by Therappy or any of its Affiliates or made by any third party (including a Service Provider, Customer or another Member) due to or arising out of, or in any way in connection with:</p>
    <ul>
        <li>your breach of this Agreement, or any contravention of any law or rights of a third party by you;</li>
        <li>your use of the Site and the Services;</li>
        <li>any advice, treatment, or other Services you provide as a Service Provider (if applicable), including any malpractice claims;</li>
        <li>Any reliance on any information on the website or Platform; the content is provided to you as general information only and is not intended to substitute or replace the advice of a duly qualified professional (where applicable);</li>
        <li>your transactions involving the Site;</li>
        <li>any of your Content and/or information that you submit, post, or transmit through the Site and/or Services;</li>
        <li>any statement or representation you make to, or any conduct you engage in towards, another Member;</li>
        <li>in the case of Customers, the receipt of Services by, you, or otherwise arising out of or in connection with any Service Provider Contract to which you are a party; and</li>
        <li> any claim arising out of any employment relationship, including but not limited to any industrial instrument and/or statutory entitlement, including superannuation obligations.</li>
    </ul>
    <p>To the fullest extent permitted by law, the liability of Therappy, to the Member:</p>
    <ul>
        <li>is limited to the refund of amounts paid by the Member to Therappy for Services in the 12 months prior to the date on which the liability arose;</li>
        <li>excludes any liability for any indirect, consequential, or pure economic loss; and</li>
        <li>is reduced to the extent that the liability arises out of the negligent or wrongful act or omission of the Member or any third party,</li>
    </ul>
    <p>and each of the above limitations or exclusions are a separate term of this Agreement.</p>
    
    <p class='policy-article'>Acknowledgment by Member</p>
    <p>You agree and acknowledge that the exclusions and limitations of liability and warranty in, and the indemnity given by you under, this clause are reasonable to protect the legitimate interests of Therappy in circumstances where:</p>
    <ul>
        <li>Therappy does not provide the Services, and acts as a facilitator only; and</li>
        <li>any Services Provider contract and any engagement to provide Services is between you and the Service Provider or Customer, Member or Guest.</li>
    </ul>
    <p class='policy-article'>Any liability as between Services Provider and Customer unaffected</p>
    <p>Nothing in this Agreement is intended to limit any liability owed by a Service Provider to a Customer, or by a Customer to a Service Provider, including (without limitation) for any claim as a consumer under the ACL, or to limit or modify any right or remedy the Customer has against the Service Provider for failure of a statutory guarantee under the ACL.</p>
    
    <p><strong>Bar to claims brought in breach of this clause:</strong> </p>
    <p>Therappy and any of its Affiliates may plead this Agreement in bar to any claim, action, proceeding, class action or suit brought by you against Therappy or any of its Affiliates in any proceeding or claim commenced in breach of this clause.</p>
    
    <p class='policy-article'>General</p>
    <p><strong>Notices:</strong>We will give notice and provide communications to you by text, email, by posting on the Site in the Member Hub, or other reasonable electronic means.  You must give notice to us in writing via email to <a class="link-text-decorate-none" href="mailto:help@Therappy.com.au">help@Therappy.com.au</a> or as otherwise expressly provided. Please report any violations of this Agreement to <a class="link-text-decorate-none" href="mailto:help@Therappy.com.au">help@Therappy.com.au</a>.</p>
    <p><strong>Electronic communications: </strong>You consent to receiving communications from us in an electronic form, and agree that all terms and conditions, Agreements, notices, documents, disclosures, and other communications ("<strong>Communications</strong>") that we provide to you electronically satisfy any legal requirement that such Communications would satisfy if it were in writing.  Your consent to receive Communications and do business electronically, and our Agreement to do so, applies to all of your interactions and transactions with us.</p>
    <p><strong>No third-party beneficiaries:</strong>You understand and agree that, except as otherwise expressly provided in this Agreement, there shall be no third-party beneficiaries to this Agreement.</p>
    <p><strong>Governing law and jurisdiction: </strong>This Agreement and the relationship between you and Therappy, will be governed by the laws of the State of New South Wales, Australia.  The parties agree to the non-exclusive jurisdiction of the courts of New South Wales, Australia, and of courts entitled to hear appeals from those Courts.</p>
    <p><strong>Assignment: </strong>You must not transfer, assign, or delegate Your right and/or duties under this Agreement to anyone else and any attempted assignment or delegation is void.  We may assign or novate this Agreement at any time by giving notice to you.</p>
    <p><strong>Headings: </strong>Headings and paragraph titles are for convenience only and do not form part of the terms of this Agreement.</p>
    <p><strong>Delay and waiver: </strong>Any delay or failure by Therappy to exercise or enforce any right or provision of this Agreement will not constitute a waiver of such right or provision.  No waiver by Us will have effect unless such waiver is set forth in writing, signed by Us, nor will any such waiver of any breach or default constitute a waiver of any subsequent or similar breach or default.</p>
    <p><strong>Whole agreement: </strong>This Agreement constitutes the entire Agreement between You and Therappy with respect to its subject matter, and supersedes all prior oral or written understandings, communications, or agreements.</p>
    <p> <strong>Severability: </strong>If for any reason a court holds any provision of this Agreement, or portion thereof, to be unenforceable, that provision of the Agreement will be read down to the minimum extent necessary so as to give full effect to the intent of the parties, and the remainder of this Agreement will continue in full force and effect.</p>
    <p> <strong>Survival: </strong>All provisions that by their nature survive expiration or termination of this Agreement shall so survive, including all limitations on liability explicitly set forth herein and our proprietary rights in and to the Site, Content provided by Us, our website and platform, and the Services.</p>
    <p> <strong>Amendments:  </strong>We may amend the terms of this Agreement from time to time by publishing the amended terms to our website or by giving electronic notice to you. It is Your sole responsibility to check the Site from time to time to view any amendments to this Agreement.  The amendment takes effect upon your express acknowledgement or when you continuing to access and/or use the Site and/or Services.</p>
    <p><strong>Severability</strong> Any provision of, or the application of any provision of these Terms which is prohibited in any jurisdiction is, in that jurisdiction, ineffective only to the extent of that prohibition. Any provision of, or the application of any provision of these Terms which is void, illegal or unenforceable in any jurisdiction does not affect the validity, legality, or enforceability of that provision in any other jurisdiction or of the remaining provisions in that or any other jurisdiction. If a clause is void, illegal or unenforceable, it may be severed without affecting the enforceability of the other provisions in these Terms.</p>
    
    <p class='policy-article'>Definitions</p>
    <p>In these Terms:</p>
    <p><strong>Abandons</strong> the Booking means a decision made by the Service Provider to cancel a Booking the Service Provider can no longer attend by clicking the "Abandon Booking" function on the Platform.</p>
    <p><strong>Booking</strong> means an accepted Booking Request (which can be extended by you after the Service Provider has accepted the Booking Request) for the provision of the Service which has been processed on the Platform and communicated to the Service Provider via the Platform.</p>
    <p><strong>Booking Request </strong>means a booking request made by you through the Platform.</p>
    <p><strong>Booking Request details</strong> means the details you have entered into the Booking Request including but not limited to the details of the Property, the requested time and date of the Wellness Service, the type of Wellness Service required, the preferred gender of the Service Provider, preferred Booking Time, and your contact details.</p>
    <p><strong>Booking System </strong>means the online booking system on the Platform used to make a Booking Request in accordance with this Agreement.</p>
    <p><strong>Content</strong> means all material, content and information made available on the Platform and/or Therappy's official social media accounts, including but not limited to feedback, reviews and ratings provided by you, users of the Platform or Website and Service Providers, any written text, graphics, images, photographs, logos, trademarks, audio material, video material and any other forms of expression.</p>
    <p><strong>Customer, you or your</strong> means a person who uses the Platform.</p>
    <p><strong>Direct Booking Request</strong> means a Booking Request made by you offered to a specific Service Provider.</p>
    <p><strong>GST</strong> means Goods and Services Tax pursuant to A New Tax System (Goods & Services Tax) Act 1999 (Cth) and the related imposition Act of the Commonwealth.</p>
    <p><strong>Intellectual Property Rights </strong>means all present and future rights anywhere in the world conferred by statute, common law, or equity in or in relation to any copyright, trademarks, designs, patents, circuit layouts, business and domain names, inventions, confidential information, trade secrets and other results of intellectual activity in the industrial, commercial, scientific, literary, or artistic fields.</p>
    <p><strong>Losses</strong> mean any and all actions, claims, proceedings, judgments, demands, liabilities, losses, costs (including reasonable legal costs), expenses, penalties, fines and damages which may be brought or awarded against Therappy or suffered, incurred or paid by Therappy (howsoever arising) in respect of, arising out of or in connection with the Wellness Services provided by that Service Provider (or any of the Service Providers' employees or agents), a violation of any law by the Service Provider, or a breach of the terms, conditions, covenants, warranties and representations (express or implied) contained in these Terms on the part of the Service Provider (or any of the Service Provider's employees or agents) and include loss of data, interruption of business or any consequential or incidental damages.</p>
    <p><strong>Platform Fee</strong> means the amount inclusive of GST displayed on the Platform as being payable by you to Therappy for the use of the Platform and Support on the acceptance of a Booking Request.</p>
    <p><strong>Payment Method</strong> means the method of payment for bookings and products available on the Therappy platform - including but not limited to direct bank transfer, debit card, credit card, PayPal, ApplePay or Afterpay.</p>
    <p><strong>Platform</strong> means the suite of software applications including the Support that enables you to book a Service Provider offered via the Mobile Application or the Website.</p>
    <p><strong>Property</strong> means the location chosen by you for the Service Provider to provide the Service.</p>
    <p><strong>Rebooking Request</strong> means a Direct Booking Request made by you offered to a Specific Service Provider that you have booked before.</p>
    <p><strong>Service Fee</strong> means the amount inclusive of GST displayed on the Platform as being payable by you to the Service Provider, and collected by the Therappy platform on behalf of the Service Provide, on the acceptance of a Booking Request which can be subject to change if you extend or change the Booking Request Details of the  Services.</p>
    <p><strong>Services </strong>means the services provided as part of massage therapy, beauty therapy and other therapeutic or allied health treatments, including but not limited to remedial massage, sports massage, Swedish / relaxation massage and pregnancy massage provided by a Service Provider in accordance with these Terms.</p>
    <p><strong>Service Provider</strong> means an individual (including a person trading under a business name), approved by Therappy to use the Platform to offer their services or products to clients.</p>
    <p><strong>Rebooking Request,</strong> or selected from an online directory of providers, or has been referred to you by another user or admin.</p>
    <p><strong>Specific Service Provider </strong>means a specific Service Provider you have used before as requested in a</p>
    <p><strong>Support</strong> means the technical support, resources and information, payment and maintenance services for the Platform and updates to the Platform software that Therappy may make generally available to you and the Service Provider.  </p>
    <p><strong>Terms</strong> means the terms and conditions set out in this document.  </p>
    <p><strong>Therappy</strong> or we or us means Therappy Pty Ltd ABN 69 666 183 857.</p>
    <p><strong>User/Member Profile</strong> means a profile created on the Platform by you and includes your name, address, phone number, gender, and credit card details.</p>
    <p><strong>Website/Site</strong> means the website at the domain name <a class="link-text-decorate-none" href="https://getblys.com.au">https://therappy.com.au</a> or <a class="link-text-decorate-none" href="https://getblys.com">https://therappy.com</a> (or such other domain names as Therappy may use in relation to the Business from time to time).</p>
    </div>
`;

  return (
    <>
      <Header />
      <Container>
        <Grid sx={{ pt: 12, pb: 5 }} container spacing={1}>
          {!isMobile && (
            <Grid item md={3}>
              <MenuList className={'list-item-policy'}>
                <StyledLink to={PublicRouteEnum.PRIVACY_POLICY}>
                  <MenuItem sx={{ mb: 3 }}>Privacy Policy</MenuItem>
                </StyledLink>

                <StyledLink to={PublicRouteEnum.THERAPPY_PRIVACY_POLICY}>
                  <MenuItem sx={{ mb: 3 }}>Therappy Privacy Policy</MenuItem>
                </StyledLink>

                <StyledLink to={PublicRouteEnum.TERMS_AND_CONDITIONS}>
                  <MenuItem sx={{ mb: 3 }}>Website Terms & Conditions</MenuItem>
                </StyledLink>

                <StyledLink to={PublicRouteEnum.SERVICE_PROVIDER_TERMS_CONDITIONS}>
                  <MenuItem sx={{ mb: 3 }}>Service Provider Terms & Conditions</MenuItem>
                </StyledLink>
                <MenuItem className={'list-item-policy-active'} sx={{ mb: 3, color: colors.primary[70] }}>Customer Terms & Conditions</MenuItem>

              </MenuList>
            </Grid>
          )}
            {isMobile && (
                <Grid item xs={12}>
                    <MenuList className={'list-item-policy'}>
                        <StyledLink to={PublicRouteEnum.PRIVACY_POLICY}>
                            <MenuItem sx={{ mb: 3, justifySelf: 'center' }}>Privacy Policy</MenuItem>
                        </StyledLink>

                        <StyledLink to={PublicRouteEnum.THERAPPY_PRIVACY_POLICY}>
                            <MenuItem sx={{ m: 0, p: 0, justifySelf: 'center' }}>Therappy Privacy Policy</MenuItem>
                        </StyledLink>

                        <StyledLink to={PublicRouteEnum.TERMS_AND_CONDITIONS}>
                            <MenuItem sx={{ m: 0, p: 0, justifySelf: 'center' }}>Website Terms & Conditions</MenuItem>
                        </StyledLink>

                        <StyledLink to={PublicRouteEnum.SERVICE_PROVIDER_TERMS_CONDITIONS}>
                            <MenuItem sx={{ m: 0, p: 0, justifySelf: 'center' }}>Service Provider Terms & Conditions</MenuItem>
                        </StyledLink>

                        <MenuItem className={'list-item-policy-active'} sx={{ mb: 3, color: colors.primary[70], justifySelf: 'center' }}>Customer Terms & Conditions</MenuItem>
                    </MenuList>
                </Grid>
            )}
          <Grid item xs={12} md={9} lg={8} xl={7}>
            <div dangerouslySetInnerHTML={{__html: htmlContent}}/>
          </Grid>
        </Grid>
      </Container>
      <Footer/>
    </>
  );
};

export default CustomerTermsConditions;
